// problemStatementReducer.js

import { createSlice } from '@reduxjs/toolkit';

export const problemStatement = createSlice({
  name: 'problemStatement',
  initialState: {
    humanInput: '',
    aiResponse: '',
  },
  reducers: {
    setHumanInput: (state, action) => {
      console.log('INSLICE HUMAN INPUT: ', action.payload);
      state.humanInput = action.payload;
    },

    setAiResponse: (state, action) => {
      console.log('AI RESPONSE: ', action.payload);
      state.aiResponse = action.payload;
    },
  },
});

export const { setHumanInput, setAiResponse } = problemStatement.actions;

export default problemStatement.reducer;
