// services/librarianService.js
import { flashMessage } from '../utils/FlashMessage';

const appUrl = process.env.REACT_APP_API_URL;

const updateLibrarianQueryRating = async (orgId, videoId, query, rating, accessToken) => {
  console.log('In updateLibrarianQueryRating:');
  try {
    const response = await fetch(`${appUrl}/api/services/updateLibrarianQueryRating`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ orgId, videoId, query, rating }),
    });

    if (!response.ok) {
      throw new Error(`Error in fetching Lens: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    flashMessage.error(error);
    throw error;
  }
};

export default updateLibrarianQueryRating;
