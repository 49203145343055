import React, { useMemo } from 'react';
import { withAuthInfo } from '@propelauth/react';
import { Box, Typography } from '@mui/material';
import VideosWithQueryRatings from './DashBoard/VideosWithQueryRatings';

const Librarian = withAuthInfo(({ userClass, AccessToken }) => {
  // Memoize videos to prevent re-renders unless `query` changes
  const videosComponent = useMemo(() => <VideosWithQueryRatings userClass={userClass} AccessToken={AccessToken} />, [userClass, AccessToken]);

  return (
    <Box maxWidth={1024} sx={{ mt: 12, ml: 16 }}>
      <Typography variant="h6" sx={{ mt: 2 }}>
        Librarian Dashboard
      </Typography>
      {videosComponent}
    </Box>
  );
});

export default Librarian;

