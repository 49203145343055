import React from 'react';
import { Box, TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useChatMemory } from '../../ChatMemoryProvider/ChatMemoryProvider'; // Adjust the import path as needed

const SearchBar = ({ onSearch }) => {
  const { searchQuery, setSearchQuery } = useChatMemory();

  const handleSearch = (e) => {
    if (e.key === 'Enter' && searchQuery.trim() !== '') {
      onSearch(searchQuery); // Notify the parent component about the search
      e.preventDefault();
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  return (
    <Box sx={{ display: 'flex', minWidth: '600px', justifyContent: 'center', p: 2 }}>
      <TextField
        label="Ask the expert"
        helperText="Ask a question or search for topics"
        variant="outlined"
        fullWidth
        value={searchQuery}
        onChange={handleChange}
        onKeyDown={handleSearch}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
        }}
      />
    </Box>
  );
};

export default SearchBar;
