import React, { useEffect, useState } from 'react';
import { withAuthInfo } from '@propelauth/react';
import { Box, CircularProgress, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Dialog, DialogTitle, DialogContent, TextField, DialogActions, Button } from '@mui/material';
import getVideosWithLibrarianRatings from '../../../../services/videoService';
import updateLibrarianQueryRating from '../../../../services/librarianService';

const VideosWithQueryRatings = withAuthInfo(({ userClass, accessToken }) => {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(true); // Set to true initially to show the loading state
  const [error, setError] = useState(null);
  const { orgId } = userClass.getOrgs()[0];
  const [open, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [selectedRating, setSelectedRating] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const responseData = await getVideosWithLibrarianRatings(orgId, accessToken);
        setData(responseData);
      } catch (err) {
        setError(err);
      } finally {
        setIsFetching(false);
      }
    };

    fetchData();
  }, [orgId, accessToken]);

  const handleClickOpen = (video, rating) => {
    setSelectedVideo(video);
    setSelectedRating(rating);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedVideo(null);
    setSelectedRating(null);
  };

  const handleRatingChange = (e) => {
    setSelectedRating({ ...selectedRating, rating: e.target.value });
  };

  const handleUpdateRating = async () => {
    try {
      await updateLibrarianQueryRating(orgId, selectedVideo.metadata.id, selectedRating.query, selectedRating.rating, accessToken);
      const updatedData = data.uniqueVideoDocuments.map((video) => {
        if (video.metadata.id === selectedVideo.metadata.id) {
          return {
            ...video,
            metadata: {
              ...video.metadata,
              queryRatings: video.metadata.queryRatings.map((qr) => (qr.query === selectedRating.query ? selectedRating : qr)),
            },
          };
        }
        return video;
      });
      setData({ uniqueVideoDocuments: updatedData });
    } catch (updateError) {
      console.error('Failed to update rating', updateError);
    } finally {
      handleClose();
    }
  };

  if (isFetching) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress size="4rem" />
      </Box>
    );
  }

  if (error) {
    return 'An error has occurred';
  }

  if (!data || !data.uniqueVideoDocuments || data.uniqueVideoDocuments.length === 0) {
    return (
      <Box alignItems="center" mt="20px">
        <Typography variant="h6">
          No videos matching your question were found. Please, try another question.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{
      backgroundColor: 'rgba(211, 211, 211, 0.3)', // Semi-transparent background
      borderRadius: 2,
      p: 3,
      textAlign: 'center',
      width: '100%',
      maxWidth: '1200px',
    }}
    >
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Query Ratings</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.uniqueVideoDocuments.map((video) => (
              <TableRow key={video.metadata.id}>
                <TableCell>{video.metadata.title}</TableCell>
                <TableCell>
                  {video.metadata.queryRatings.map((rating) => (
                    <div key={rating.query}>
                      <Button size="small" onClick={() => handleClickOpen(video, rating)} sx={{ textAlign: 'left' }}>
                        {rating.query}: {rating.rating}
                      </Button>
                    </div>
                  ))}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle>Edit Rating</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              label="Rating"
              type="number"
              fullWidth
              value={selectedRating ? selectedRating.rating : ''}
              onChange={handleRatingChange}
              inputProps={{ min: 1, max: 5 }}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={handleUpdateRating} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </TableContainer>
    </Box>
  );
});

export default VideosWithQueryRatings;

