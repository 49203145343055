import React, { useState } from 'react';
import { Drawer, Box, IconButton, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import UserChatSessions from '../UserContent/UserChatSessions';
import AskCoachMarty from '../AskCoachMarty/AskCoachMarty';

const ChatEAI = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <Grid container sx={{ height: '100vh' }}>
      <IconButton onClick={toggleDrawer} sx={{ position: 'absolute', top: 8, left: 16, zIndex: 1300 }}>
        <MenuIcon />
      </IconButton>
      <Drawer
        variant="persistent"
        anchor="left"
        open={isDrawerOpen}
        sx={{
          width: isDrawerOpen ? 600 : 0,
          flexShrink: 0,
          transition: 'width 0.3s',
          '& .MuiDrawer-paper': {
            width: isDrawerOpen ? 600 : 0,
            boxSizing: 'border-box',
            overflowX: 'hidden',
            transition: 'width 0.3s',
            paddingTop: '24px',
          },
        }}
      >
        {isDrawerOpen && <UserChatSessions />}
      </Drawer>
      <Box
        component="main"
        sx={{ flexGrow: 1, marginLeft: isDrawerOpen ? 80 : 10, transition: 'margin-left 0.3s' }}
      >
        <AskCoachMarty />
      </Box>
    </Grid>
  );
};

export default ChatEAI;
