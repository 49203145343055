import React from 'react';
import { Collapse, Box, Typography, IconButton } from '@mui/material';
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai';

const FAQItem = ({ open, toggle, question, answer }) => (
  <Box pt={1}>
    <Box
      sx={{
        backgroundColor: 'white',
        py: 2,
        px: 4,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        cursor: 'pointer',
      }}
      onClick={toggle}
    >
      <Typography variant="h6" sx={{ color: 'green' }}>
        {question}
      </Typography>
      <IconButton sx={{ color: 'green', fontSize: '30px' }}>
        {open ? <AiOutlineMinus /> : <AiOutlinePlus />}
      </IconButton>
    </Box>
    <Collapse in={open}>
      <Box sx={{ backgroundColor: 'white', px: 4, pb: 2 }}>
        <Typography>{answer}</Typography>
      </Box>
    </Collapse>
  </Box>
);

export default FAQItem;
