import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { useNavigate } from 'react-router-dom';

const NotAuthorizedPage = () => {
  const navigate = useNavigate();

  const goToPricing = () => {
    navigate('/pricing');
  };

  const goToLandingPage = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        bgcolor: '#f5f5f5',
      }}
    >
      <LockOutlinedIcon color="error" style={{ fontSize: 100 }} />

      <Typography variant="h4" gutterBottom>
        Access Denied
      </Typography>

      <Typography variant="h6" gutterBottom>
        It seems you don&apos;t have a paid subscription.
      </Typography>

      <Typography variant="body1" sx={{ mb: 4 }}>
        In order to access this page, you need to renew your subscription. <br />
        If you just signed up please respond to the verification email prior to logging in.
      </Typography>

      <Button variant="contained" color="primary" onClick={goToPricing} sx={{ marginRight: 2 }}>
        Go to Pricing
      </Button>

      <Button variant="contained" color="secondary" onClick={goToLandingPage}>
        Home
      </Button>
    </Box>
  );
};

export default NotAuthorizedPage;
