import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import courseCategoryReducer from '../features/courseCategory';
import userReducer from '../features/auth';
import problemStatementReducer from '../features/problemStatement';
import epicBriefReducer from '../features/epicBrief';
import chatHistoryReducer from '../features/chatHistorySlice';

const store = configureStore({
  reducer: {
    courseCategory: courseCategoryReducer,
    user: userReducer,
    problemStatement: problemStatementReducer,
    epicBrief: epicBriefReducer,
    chatHistory: chatHistoryReducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

setupListeners(store.dispatch);

export default store;
