import React from 'react';
import { Box, Typography } from '@mui/material';
import { stats } from '../../constants';

const Stats = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      flexWrap: 'wrap',
      marginBottom: { xs: 6, sm: 20 },
    }}
  >
    {stats.map((stat) => (
      <Box
        key={stat.id}
        sx={{
          flex: 1,
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'center',
          flexDirection: 'row',
          marginLeft: 3,
        }}
      >
        <Typography
          component="h4"
          variant="h4"
          sx={{
            fontWeight: 'bold',
            fontSize: { xs: '30px', sm: '40px' },
            lineHeight: { xs: '43px', sm: '53px' },
          }}
        >
          {stat.value}
        </Typography>
        <Typography
          component="p"
          variant="body1"
          sx={{
            fontWeight: 'normal',
            fontSize: { xs: '15px', sm: '20px' },
            lineHeight: { xs: '21px', sm: '26px' },
            textTransform: 'uppercase',
            margin: 3,
            color: 'gradient.main',
          }}
        >
          {stat.title}
        </Typography>
      </Box>
    ))}
  </Box>
);

export default Stats;

