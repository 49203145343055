import React, { createContext, useCallback, useContext, useReducer, useEffect, useState, useMemo } from 'react';
import chatReducer from './utils/chatReducer';
import useChatSocket from './utils/useChatSocket';
import initializeMemoryAndFetchHistory from './utils/initializeChat';
import { getUserChatSessions, renameChatMemory, deleteChatMemory } from '../../services/chatService';

const ChatMemoryContext = createContext({
  historyId: null,
  lens: null,
  userChatSessions: [],
  chatHistory: [],
  isInitialized: false,
  setChatHistory: () => {},
  setSessionInfo: () => {},
  clearError: () => {},
  dispatch: () => {},
});

const initialState = {
  historyId: null,
  selectedSessionId: null,
  lens: null,
  userChatSessions: [],
  chatHistory: [],
  isLoading: false,
  error: null,
  searchQuery: '',
};

const ChatMemoryProvider = ({ children, user }) => {
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const [isInitialized, setIsInitialized] = useState(false);
  const serverUrl = process.env.REACT_APP_API_URL;

  useChatSocket(serverUrl, state.historyId, user, dispatch);

  const setSessionInfo = useCallback(async (newHistoryId, newLens) => {
    dispatch({
      type: 'SET_SESSION_INFO',
      payload: { historyId: newHistoryId, lens: newLens },
    });
    setIsInitialized(false);
  }, [dispatch]);

  useEffect(() => {
    (async () => {
      if (state.historyId && !isInitialized) {
        await initializeMemoryAndFetchHistory(user.email, state.historyId, state.lens, dispatch)
          .then(() => {
            setIsInitialized(true);
          });
      }
    })();
  }, [state.historyId, user.email, dispatch, isInitialized]);

  const setSelectedSessionId = useCallback((sessionId) => {
    dispatch({
      type: 'SET_SELECTED_SESSION',
      payload: sessionId,
    });
  }, [dispatch]);

  const clearSelectedSessionId = useCallback(() => {
    dispatch({
      type: 'CLEAR_SELECTED_SESSION',
    });
  }, [dispatch]);

  const addNewSession = useCallback(async (newSession) => {
    dispatch({ type: 'ADD_NEW_SESSION', payload: newSession });
    dispatch({ type: 'SET_SELECTED_SESSION_ID', payload: newSession.sessionId });
  }, [dispatch]);

  const renameSession = useCallback(async (sessionId, newName) => {
    dispatch({
      type: 'RENAME_SESSION',
      payload: { sessionId, newName },
    });
    await renameChatMemory(sessionId, newName);
  }, [dispatch]);

  const deleteSession = useCallback(async (sessionId) => {
    dispatch({
      type: 'DELETE_SESSION',
      payload: { sessionId },
    });
    await deleteChatMemory(sessionId);
  }, [dispatch]);

  useEffect(() => {
    const fetchSessions = async () => {
      if (user?.email) {
        try {
          const sessions = await getUserChatSessions(user);
          sessions.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
          dispatch({
            type: 'SET_USER_CHAT_SESSIONS',
            payload: sessions,
          });
        } catch (error) {
          console.error('Failed to fetch user chat sessions:', error);
        }
      }
    };

    fetchSessions();
  }, [user?.email]);

  const contextValue = useMemo(() => ({
    ...state,
    isInitialized,
    dispatch,
    setSessionInfo,
    setSelectedSessionId,
    clearSelectedSessionId,
    addNewSession,
    renameSession,
    deleteSession,
    setSearchQuery: (query) => dispatch({ type: 'SET_SEARCH_QUERY', payload: query }),
  }), [state, isInitialized, setSessionInfo, setSelectedSessionId, clearSelectedSessionId, addNewSession, renameSession, deleteSession]);

  return (
    <ChatMemoryContext.Provider value={contextValue}>
      {children}
    </ChatMemoryContext.Provider>
  );
};

export default ChatMemoryProvider;
export const useChatMemory = () => useContext(ChatMemoryContext);
