import React from 'react';
import { Field } from 'formik';
import { TextField } from '@mui/material';

const FieldComponent = ({ name, label, type = 'number' }) => (
  <Field name={name}>
    {({ field, form }) => (
      <TextField
        {...field}
        type={type}
        label={label}
        fullWidth
        margin="normal"
        error={form.touched[name] && Boolean(form.errors[name])}
        helperText={form.touched[name] && form.errors[name]}
      />
    )}
  </Field>
);

export default FieldComponent;
