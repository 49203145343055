import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import VideoCard from './VideoCard';

const VideoIconPopup = ({ videoUrl }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClickOpen} aria-label="info">
        <InfoIcon sx={{ color: '#3d85c6' }} />
      </IconButton>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogContent>
          <VideoCard url={videoUrl} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default VideoIconPopup;
