import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Assistant from './Assistant';
import assistants from './assistants';

const gridAlignProps = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};

const MainPage = () => {
  const theme = useTheme();

  return (
    <Box flex={1} p={2} sx={{ ...theme.mixins.toolbar, display: { xs: 'none', md: 'block' } }}>
      <div style={{ ...theme.mixins.toolbar }} />
      <Box p={2} boxShadow={2}>
        <Typography variant="h4" noWrap align="center" color="#05472A" gutterBottom>Choose your Copilot</Typography>
        <Typography variant="h6" noWrap align="center" color="#05472A" gutterBottom>Create, Evaluate, Learn</Typography>
        <Grid container spacing={2} sx={{ marginLeft: 0, paddingRight: 2, paddingBottom: 2 }}>
          {assistants.map(({ icon, title, desc, slug, category, externalUrl }, index) => (
            <Grid key={index} item xs={12} sm={6} md={4} lg={3} xl={2} sx={{ ...gridAlignProps, minHeight: 300 }}>
              <Assistant
                IconComponent={icon}
                title={title}
                desc={desc}
                link={category.startsWith('VOTE') ? externalUrl : `/aiassistant/${slug}`}
                externalLink={category.startsWith('VOTE')}
                category={category}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default MainPage;
