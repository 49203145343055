
const appUrl = process.env.REACT_APP_API_URL;

export const updateAdminUserSettings = async (userId, userSettings) => {
  const response = await fetch(`${appUrl}/api/customer/updateAdminUserSettings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, userSettings }),
  });

  if (!response.ok) {
    throw new Error('Failed to update user settings');
  }

  return response.json();
};

export const getAdminUserSettings = async (userId) => {
  const response = await fetch(`${appUrl}/api/customer/getAdminUserSettings`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId }),
  });

  // If the user does not exist, return null
  if (response.status === 404) {
    return null;
  } if (!response.ok) {
    throw new Error('Failed to get user settings');
  }

  return response.json();
};

