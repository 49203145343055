import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReactRotatingText from 'react-rotating-text';
import { withAuthInfo } from '@propelauth/react';
import { useNavigate } from 'react-router-dom';

import { robot2 } from '../../assets';

const Hero = withAuthInfo(({ isLoggedIn }) => {
  const navigate = useNavigate();
  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home');
    }
  }, [isLoggedIn, navigate]);

  return (
    <Box id="home" sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, py: 2 }}>
      <Box sx={{ flex: '1', display: 'flex', flexDirection: 'column', alignItems: 'start', px: { xl: 0, sm: 16, xs: 6 } }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
          <Typography variant="h3" sx={{ flex: '1', fontWeight: 'bold', fontSize: { ss: '72px', xs: '52px' }, lineHeight: { ss: '100px', xs: '75px' } }}>
            The Next <Box component="br" display={{ xs: 'none', sm: 'block' }} />
            <span style={{ background: 'linear-gradient' }}>Generation AI</span>
          </Typography>

        </Box>

        <Typography variant="h3" sx={{ fontWeight: 'bold', fontSize: { ss: '68px', xs: '52px' }, lineHeight: { ss: '100px', xs: '75px' }, width: '100%' }}>
          <ReactRotatingText items={['Product Manager', 'Product Owner', 'Scrum Master']} />
        </Typography>

        <Typography variant="body1" sx={{ maxWidth: '470px', mt: 5 }}>
          Our AI coach expert integrates with you where you need it, when you need
          it.
        </Typography>
      </Box>

      <Box sx={{ flex: '1', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', my: { md: 0, xs: 10 } }}>
        <img src={robot2} alt="aiimage" style={{ width: '100%', height: '100%', position: 'relative', zIndex: 5 }} />
        <Box sx={{ position: 'absolute', zIndex: 1, width: '80%', height: '80%', bottom: 40, bgcolor: 'white', borderRadius: '50%' }} />
      </Box>
    </Box>
  );
});

export default Hero;

