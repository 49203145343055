import React, { useEffect } from 'react';

const ActiveCampaignForm = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://bciconsultants.activehosted.com/f/embed.php?id=5';
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <div id="activeCampaignForm">
      {/* The form will be loaded into this div by the script */}
    </div>
  );
};

export default ActiveCampaignForm;
