// SuccessPage.jsx
import React, { useEffect, useState } from 'react';
import { Box, Typography, Button } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useNavigate, useLocation } from 'react-router-dom';

const SuccessPage = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const sessionId = queryParams.get('session_id');
  const navigate = useNavigate();

  const [customer, setCustomer] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const appUrl = process.env.REACT_APP_API_URL;
    const fetchData = async () => {
      try {
        const response = await fetch(`${appUrl}/api/payment/createCustomer`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId }),
        });
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data = await response.json();
        setCustomer(data);
      } catch (e) {
        setError(e.message);
      }
    };

    fetchData();
  }, [sessionId]);

  const goToHome = () => {
    navigate('/');
  };

  if (error) return <div>Error: {error}</div>;
  if (!customer) return 'Loading...'; // Show a loading message while the customer data is being fetched
  const fName = customer.name.split(' ')[0];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
        padding: 3,
        backgroundColor: '#F1FAEE', // Mint Cream
      }}
    >
      <CheckCircleIcon sx={{ fontSize: 100, color: '#2A9D8F' }} /> {/* Forest Green */}

      <Typography variant="h2" gutterBottom>
        Payment Successful, {fName}!
      </Typography>

      <Typography variant="h4" gutterBottom>
        Welcome to Evergreen AI
      </Typography>

      <Typography variant="body1" gutterBottom>
        Your email is {customer.email}. You will receive an email to complete your login. <br />Once you have verified your email you will be redirected to the Home page.
      </Typography>

      <Button
        variant="contained"
        color="primary"
        size="large"
        sx={{ mt: 3 }}
        onClick={goToHome}
      >
        Home
      </Button>
    </Box>
  );
};

export default SuccessPage;
