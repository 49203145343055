/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { withAuthInfo } from '@propelauth/react';
import { Box, CircularProgress, Typography, useMediaQuery } from '@mui/material';
import VideoList from './VideoList';

const Videos = withAuthInfo(({ userClass, query }) => {
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const lg = useMediaQuery((theme) => theme.breakpoints.only('lg'));

  const appUrl = process.env.REACT_APP_API_URL;
  const { orgId } = userClass.getOrgs()[0];

  useEffect(() => {
    const storedData = localStorage.getItem('videoData');
    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }, []);

  useEffect(() => {
    if (!query || query.trim() === '') {
      setIsFetching(false);
      return;
    }
    setIsFetching(true);
    const requestBody = {
      query, orgId,
    };

    fetch(`${appUrl}/api/chain/retrieveVideos`, {
      method: 'POST', // specify the HTTP method
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData);
        setIsFetching(false);
        localStorage.setItem('videoData', JSON.stringify(responseData));
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
      });
  }, [query, appUrl, orgId]);

  const numberOfVideos = lg ? 16 : 18;

  if (isFetching) {
    return <Box display="flex" justifyContent="center"><CircularProgress size="4rem" /></Box>;
  }

  if (error) return 'An error has occurred';

  if (!data || !data.uniqueVideoDocuments || data.uniqueVideoDocuments.length === 0) {
    return <Box alignItems="center" mt="20px"><Typography variant="h6">No videos matching your question were found. Please, try another question.</Typography></Box>;
  }

  return <VideoList videos={data.uniqueVideoDocuments} numberOfVideos={numberOfVideos} query={query} />;
});

export default Videos;
