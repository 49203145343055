import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import ReactPlayer from 'react-player';

const VideoCard = ({ url }) => (
  <Card sx={{
    maxWidth: 600,
    margin: 'auto',
    borderRadius: 1,
    boxShadow: 3,
    background: 'linear-gradient(to bottom right, #1565c0, #1e88e5)',
  }}
  >
    <CardContent sx={{ display: 'flex', justifyContent: 'center' }}>
      <ReactPlayer
        url={url}
        controls="true"
        width="100%"
        height="100%"
        playing="true"
        style={{ borderRadius: '4px' }}
      />
    </CardContent>
  </Card>
);

export default VideoCard;

