import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
  IconButton, Menu, MenuItem, Typography, TableSortLabel, TablePagination,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { formatDistanceToNow, isValid } from 'date-fns';
import { useChatMemory } from '../ChatMemoryProvider/ChatMemoryProvider';
import ConfirmDeleteDialog from './utils/confirmDelete';
import RenameDialog from './utils/renameDialog';

const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (order, orderBy) => (order === 'desc'
  ? (a, b) => descendingComparator(a, b, orderBy)
  : (a, b) => -descendingComparator(a, b, orderBy));

const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

const UserChatSessions = () => {
  const {
    userChatSessions, renameSession, deleteSession, setSessionInfo, setSelectedSessionId, selectedSessionId,
  } = useChatMemory();
  const navigate = useNavigate();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [openRenameDialog, setOpenRenameDialog] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('updatedAt');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleMenuClick = (event, session) => {
    event.stopPropagation();
    setSelectedSession(session);
    setMenuAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const handleMenuItemRename = () => {
    setOpenRenameDialog(true);
    handleMenuClose();
  };

  const handleMenuItemDelete = () => {
    setOpenDeleteConfirm(true);
    handleMenuClose();
  };

  const handleRenameConfirm = async (newName) => {
    if (selectedSession) {
      await renameSession(selectedSession.sessionId, newName);
      // No need to reload sessions explicitly if the context updates the state
    }
    setOpenRenameDialog(false);
  };

  const handleConfirmDelete = async () => {
    if (selectedSession) {
      await deleteSession(selectedSession.sessionId);
      // No need to reload sessions explicitly if the context updates the state
    }
    setOpenDeleteConfirm(false);
    setSelectedSession(null);
  };

  const handleHistoryIdClick = (session) => {
    setSessionInfo(session.sessionId, session.metadata.lens);
    setSelectedSessionId(session.sessionId);
    navigate('/chateai');
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userChatSessions.length) : 0;

  const distanceToNow = (date) => {
    if (!isValid(new Date(date))) {
      return 'Invalid date';
    }
    return formatDistanceToNow(new Date(date));
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table stickyHeader aria-label="user chat sessions table" size="small" sx={{ '& .MuiTableCell-root': { padding: '4px 8px', fontSize: '0.8rem' } }}>
          <TableHead>
            <TableRow>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'sessionName'}
                  direction={orderBy === 'sessionName' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'sessionName')}
                >
                  <Typography variant="subtitle2" noWrap>Session Name</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'name'}
                  direction={orderBy === 'name' ? order : 'asc'}
                  onClick={(event) => handleRequestSort(event, 'name')}
                >
                  <Typography variant="subtitle2" noWrap>Type</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'updatedAt'}
                  direction={orderBy === 'updatedAt' ? order : 'desc'}
                  onClick={(event) => handleRequestSort(event, 'updatedAt')}
                >
                  <Typography variant="subtitle2" noWrap>Last Updated</Typography>
                </TableSortLabel>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {stableSort(userChatSessions, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((session) => {
                const isSelected = session.sessionId === selectedSessionId;
                const { sessionName } = session.metadata;
                const primaryText = sessionName || session.createdAt || 'New Chat';
                const lastUpdatedText = distanceToNow(new Date(session.updatedAt), { addSuffix: true });

                return (
                  <TableRow
                    key={session.sessionId}
                    hover
                    selected={isSelected}
                    onClick={() => handleHistoryIdClick(session)}
                    sx={{
                      cursor: 'pointer',
                      '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 0, 0, 0.08)',
                      },
                    }}
                  >
                    <TableCell component="th" scope="row" padding="none">{primaryText}</TableCell>
                    <TableCell padding="none">{session.metadata.name}</TableCell>
                    <TableCell padding="none">{lastUpdatedText}</TableCell>
                    <TableCell align="right" padding="none">
                      <IconButton onClick={(event) => handleMenuClick(event, session)} size="small">
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: 33 * emptyRows }}>
                <TableCell colSpan={4} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={userChatSessions.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Menu
        anchorEl={menuAnchorEl}
        open={Boolean(menuAnchorEl)}
        onClose={handleMenuClose}
        MenuListProps={{ 'aria-labelledby': 'session-menu-button' }}
      >
        <MenuItem onClick={handleMenuItemRename}>Rename</MenuItem>
        <MenuItem onClick={handleMenuItemDelete}>Delete</MenuItem>
      </Menu>
      <ConfirmDeleteDialog open={openDeleteConfirm} onClose={() => setOpenDeleteConfirm(false)} onConfirm={handleConfirmDelete} session={selectedSession} />
      <RenameDialog open={openRenameDialog} onClose={() => setOpenRenameDialog(false)} onRename={handleRenameConfirm} session={selectedSession} />
    </>
  );
};

export default UserChatSessions;
