import { createTheme, alpha } from '@mui/material/styles';

/* in your global CSS or in the component-specific CSS */

// Define StyledPaper styles as a function to incorporate into theme components
const getStyledPaperStyles = (theme) => ({
  ...theme.typography.body2,
  fontSize: '0.775rem',
  padding: theme.spacing(1),
  marginTop: theme.spacing(1),
  maxWidth: '100%',
  wordWrap: 'break-word',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  // Example of conditional styling based on theme mode
  backgroundColor: theme.palette.mode === 'dark' ? theme.palette.common.greenBlueGradient : theme.palette.common.lightBlueGradient,
  color: theme.palette.mode === 'dark' ? '#FFFFFF' : '#282c34',
});

const EvergreenTheme = (mode) => {
  // Common settings
  const commonSettings = {
    typography: {
      fontFamily: 'Poppins, sans-serif',
      fontWeightBold: 800,
      fontWeightMedium: 600,
      fontWeightRegular: 400,
      fontWeightSemiBold: 500,
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920,
      },
    },
    transitions: {
      create: () => '500ms',
    },
  };

  // Theme specific settings
  const theme = createTheme({
    ...commonSettings,
    palette: {
      mode,
      primary: {
        main: mode === 'dark' ? alpha('#00a152', 1) : '#3f51b5',
      },
      secondary: {
        main: mode === 'dark' ? '#f50057' : '#ff4081',
      },
      common: {
        greenBlueGradient: mode === 'dark' ? 'linear-gradient(0deg, #22c1c3 41%, #326ef7 88%)' : 'linear-gradient(to top, #48c6ef 0%, #6f86d6 100%)',
        lightBlueGradient: 'linear-gradient(to top, #5ee7df 0%, #b490ca 100%)',
      },
      text: {
        primary: mode === 'dark' ? '#def9fa' : '#333',
        secondary: mode === 'dark' ? '#9dedf0' : '#666',
        colorGradient: mode === 'dark' ? 'radial-gradient(64.18% 64.18% at 71.16% 35.69%, #def9fa 0.89%, #bef3f5 17.23%, #9dedf0 42.04%, #7de7eb 55.12%, #5ce1e6 71.54%, #33bbcf 100%)' : 'radial-gradient(circle, #ffffff, #f4f4f4)',
      },
      grid: {
        main: 'rgba(0,0,0,0.1)',
        dark: 'rgba(0,0,0,0.2)',
      },
    },
  });

  // Including StyledPaper styles in the theme's components
  theme.components = {
    MuiPaper: {
      styleOverrides: {
        root: getStyledPaperStyles(theme),
      },
    },
  };

  return theme;
};

export default EvergreenTheme;
