import { initializeChatMemory } from '../../../services/chatService';

const initializeMemoryAndFetchHistory = async (email, historyId, lens, dispatch) => {
  dispatch({
    type: 'SET_SESSION_INFO',
    payload: { historyId, lens },
  });

  try {
    dispatch({ type: 'SET_HISTORY', payload: [] });
    const chatHistoryFromStorage = await initializeChatMemory(historyId, email);
    const transformedChatHistory = chatHistoryFromStorage.chatHistory.map((entry) => ({
      type: entry.role,
      content: entry.content,
    }));

    dispatch({ type: 'SET_HISTORY', payload: transformedChatHistory });
    dispatch({ type: 'SET_LOADING', payload: false });
  } catch (error) {
    dispatch({ type: 'SET_ERROR', payload: error.message });
  }

  return historyId;
};

export default initializeMemoryAndFetchHistory;
