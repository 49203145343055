const appUrl = process.env.REACT_APP_API_URL;

export const getStatus = async (email) => {
  const response = await fetch(`${appUrl}/api/customer/getStatus`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email }),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch status');
  }
  return response.json();
};
