import React from 'react';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { CardActionArea, CardMedia } from '@mui/material';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { withAuthInfo } from '@propelauth/react';
import { useChatMemory } from '../ChatMemoryProvider/ChatMemoryProvider';
import { createChatMemory } from '../../services/chatService';

const Assistant = withAuthInfo(({ cardImage, title, desc, link, category, lensName, user }) => {
  const navigate = useNavigate();
  const { addNewSession, setSessionInfo } = useChatMemory();

  const handleSelect = async () => {
    const categoryLowerCase = category.toLowerCase();

    if (categoryLowerCase === 'copilot') {
      try {
        const newSession = await createChatMemory(user, lensName);
        if (newSession) {
          addNewSession(newSession); // Ensure this updates the context state
          setSessionInfo(newSession.sessionId, newSession.metadata.lens);
          navigate(link);
        } else {
          console.error('Failed to create new session');
        }
      } catch (error) {
        console.error('Error creating new session:', error);
      }
    } else if (categoryLowerCase.startsWith('worksheet')) {
      if (link.startsWith('http')) {
        window.location.href = link; // Use window.location.href for external links
      } else {
        navigate(link);
      }
    } else {
      console.error('Invalid category');
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 350,
        height: 350,
        m: 2,
        border: 1,
        borderRadius: 1,
        borderColor: '#d3d3d3', // light grey
        transition: '0.3s',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <CardActionArea onClick={handleSelect}>
        <CardMedia
          sx={{ height: 175, overflow: 'hidden' }}
          image={cardImage}
          alt="Assistant Image"
        />
        <CardContent>
          <Chip label={category} size="small" sx={{ mb: 1, position: 'absolute', top: 8, right: 8, backgroundColor: '#d3d3d3', color: 'secondary' }} />
          <Typography gutterBottom variant="body2" component="div" sx={{ fontSize: 12, fontWeight: 'bold', color: 'secondary' }}>
            {title}
          </Typography>
          <Typography textOverflow="ellipsis" variant="body2" sx={{ fontSize: 12, color: 'secondary' }}>
            {desc}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
});

export default Assistant;
