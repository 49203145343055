// services/agileService.js
import { flashMessage } from '../utils/FlashMessage';

const appUrl = process.env.REACT_APP_API_URL;

const getLensEndpoint = async (lens, orgInfo) => {
  // Find the lens object that matches the lens name
  const lensObj = orgInfo.orgMetadata.lenses.find((item) => item.lensName === lens);
  console.log('getLensEndpoint_lensObj: ', lensObj);

  // If lens object is found, return the endpoint, otherwise return null
  return lensObj ? lensObj.configuration.endpoint : null;
};

const fetchUsingActiveLens = async (lens, historyId, question, accessToken, userClass) => {
  // console.log('In fetchUsingActiveLens');
  const { orgId } = await userClass.getOrgs()[0];
  const orgInfo = await userClass.getOrg(orgId);
  // console.log('In fetchUsingActiveLens-orgInfo:', orgInfo);
  const lensEndpoint = await getLensEndpoint(lens, orgInfo);
  // console.log('fetchUsingActiveLens_ENDPOINT:', lensEndpoint);
  try {
    const response = await fetch(`${appUrl}/api/${lensEndpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({ historyId, question, orgId, lensName: lens }),
    });

    if (!response.ok) {
      throw new Error(`Error in fetching Lens: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    flashMessage.error(error);
    throw error;
  }
};

export default fetchUsingActiveLens;
