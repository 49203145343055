/* eslint-disable import/no-cycle */
import React from 'react';
import { Box, Typography } from '@mui/material';
import Course from './Course'; // Make sure the import is correct
import CarouselSlider from '../AppLayout/CarouselSlider';

const CourseList = ({ courses }) => {
  // filter out courses that are not published
  const publishedCourses = courses.items.filter((course) => course.status === 'published' && course.productable_type === 'Course');

  return (
    <Box>
      {/* Title for the video list */}
      <Typography variant="h6" sx={{ mt: 2 }}>
        Learn from your coaches
      </Typography>

      <CarouselSlider>
        {publishedCourses.map((course) => <Course course={course} />)}
      </CarouselSlider>
    </Box>
  );
};

export default CourseList;

