import React from 'react';
import { withAuthInfo } from '@propelauth/react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActionArea from '@mui/material/CardActionArea';
import CardActions from '@mui/material/CardActions';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import { formatUrlWithTime, formatTime } from './utils/formatUrlWithTime';
import { drunkenpmRadioLogo } from '../../assets';
import updateLibrarianQueryRating from '../../services/librarianService';

const Video = withAuthInfo(({ video, query, userClass, accessToken }) => {
  const { metadata } = video;
  const videoId = metadata.id;
  const videoUrl = formatUrlWithTime(metadata.url, metadata.start);
  const startTime = formatTime(metadata.start); // Use this for the card descriptions

  const orgs = userClass.getOrgs();
  let orgId = '';

  if (orgs.length === 1) {
    orgId = orgs[0].orgId;
  } else {
    console.error('Error: Expected exactly one organization, but found', orgs.length);
  }

  const isAdmin = userClass.isAtLeastRole(orgId, 'Admin');

  const getLibrarianRating = () => {
    if (metadata && metadata.queryRatings) {
      for (let i = 0; i < metadata.queryRatings.length; i += 1) {
        if (metadata.queryRatings[i].query === query) {
          return metadata.queryRatings[i].rating;
        }
      }
    }
    return 5; // or any default value if the query is not found
  };

  // Placeholder function for saving rating to database
  const saveRatingToDatabase = async (rating) => {
    // Your code to save the rating to the database goes here
    await updateLibrarianQueryRating(orgId, videoId, query, rating, accessToken);
  };

  const handleRatingChange = async (event, newValue) => {
    // Add your database call here to save the rating
    try {
      await saveRatingToDatabase(newValue);
      // console.log('Rating saved successfully');
    } catch (error) {
      console.error('Error saving rating:', error);
    }
  };

  return (
    <Card
      sx={{
        maxWidth: 350,
        m: 2,
        height: 350,
        border: 1,
        borderRadius: 1,
        borderColor: '#d3d3d3', // light grey
        transition: '0.3s',
        // background: 'linear-gradient(to right, #6a11cb, #2575fc)',
        // color: 'white',
        '&:hover': {
          transform: 'scale(1.05)',
          boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
        },
      }}
    >
      <CardActionArea component={RouterLink} to={videoUrl} target="_blank">
        {metadata.thumbnailUrl ? (
          <CardMedia
            component="img"
            height="175"
            image={metadata.thumbnailUrl}
            alt={metadata.title}
          />
        ) : (
          // Render the drunkempmRadioLogo as a fallback
          <CardMedia
            component="img"
            height="100"
            image={drunkenpmRadioLogo} // Make sure drunkempmRadioLogo is a valid image URL or imported image
            alt="Fallback Logo"
          />
        )}
        <CardContent sx={{ flexGrow: 1 }}>
          <Typography gutterBottom variant="body2" component="div" sx={{ fontSize: 12, fontWeight: 'bold', color: 'secondary' }}>
            {metadata.title}
          </Typography>
          <Typography textOverflow="ellipsis" variant="body2" sx={{ fontSize: 12, color: 'secondary' }}>
            Join the conversation at {startTime}
          </Typography>
        </CardContent>
      </CardActionArea>
      {isAdmin && (
        <CardActions
          sx={{
            justifyContent: 'flex-start',
            paddingLeft: 1,
            paddingBottom: 1,
          }}
        >
          <Typography component="legend" sx={{ fontSize: 12, color: 'secondary', marginRight: 1 }}>
            Librarian Rating
          </Typography>
          <Rating
            name="librarian-rating"
            size="small"
            defaultValue={getLibrarianRating}
            precision={0.5}
            sx={{ color: 'secondary' }}
            onChange={handleRatingChange}
          />
        </CardActions>
      )}
    </Card>
  );
});

export default Video;

