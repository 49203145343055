/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from 'react';
import { withAuthInfo } from '@propelauth/react';
import { Box, CircularProgress, useMediaQuery } from '@mui/material';
import CourseList from './CourseList';

// const returnToUrl = 'http://localhost:3000/courses';

const Courses = withAuthInfo((props) => {
  // const { courseCategoryId } = useSelector((state) => state.courseCategory);
  const [data, setData] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState(null);
  const lg = useMediaQuery((theme) => theme.breakpoints.only('lg'));

  const appUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const requestBody = {
      props,
    };
    fetch(`${appUrl}/api/thinkific/courses`, {
      method: 'POST', // specify the HTTP method
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((responseData) => {
        setData(responseData);
        setIsFetching(false);
      })
      .catch((err) => {
        setError(err);
        setIsFetching(false);
      });
  }, []);

  const numberOfCourses = lg ? 16 : 18;

  if (isFetching) {
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress size="4rem" />
      </Box>
    );
  }
  if (!data?.items.length) {
    return (
      <Box display="flex" alignItems="center" mt="20px">
        <CircularProgress size="4rem" />
        {/*
        <Typography variant="h4">No courses exist in that category.
          <br />
          Please search for something else.
        </Typography>
        */}
      </Box>
    );
  }
  if (error) return 'An error has occurred';
  return (
    <div>
      <CourseList courses={data} numberOfCourses={numberOfCourses} />
    </div>
  );
});

export default withAuthInfo(Courses);

