// features/chatHistorySlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = [
  { chatUser: '',
    chatMessage: '',
  },
];

const chatHistorySlice = createSlice({
  name: 'chatHistory',
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.push(action.payload);
    },
    clearChat: () => initialState,
  },
});

export const { addMessage, clearChat } = chatHistorySlice.actions;

export default chatHistorySlice.reducer;
