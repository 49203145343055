import React, { useState, useEffect, createElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import { withAuthInfo } from '@propelauth/react';
import { getStatus } from '../services/customerService';

import ChatMemoryProvider from './ChatMemoryProvider/ChatMemoryProvider';
import NavDrawer from './NavPanel/NavDrawer';
import LandingPage from './LandingPage/LandingPage';
import Roadmap from './Roadmap/Roadmap';
import AboutPage from './About/AboutPage';
import PricingPage from './Pricing/PricingPage';
import SuccessPage from './Pricing/SuccessPage';
import ErrorPage from './Pricing/ErrorPage';
import NotAuthorizedPage from './Pricing/NotAuthorizedPage';

import MainPage from './AiAssistant/MainPage';
// import WorkflowBuilder from './WorkflowAnalyzer/WorkflowBuilder';
import { Admin, Librarian } from './Admin';
import assistants from './AiAssistant/assistants';
import ExternalLinkWrapper from './AiAssistant/utils/ExternalLinkWrapper';
import SprintCapacityForm from './AiAssistant/SprintCapacity/SprintCapacityForm';
import PersonalAgilityCanvas from './AiAssistant/PersonalAgilityCanvas/PersonalAgilityCanvas';
import SplitScreenLayout from './AppLayout/SplitScreenLayout';
import ContentPage from './AppLayout/ContentPage';
import AskCoachMarty from './AskCoachMarty/AskCoachMarty';
import ChatEAI from './AppLayout/ChatEAI';

const assistantComponents = {
  sprintcapacity: SprintCapacityForm,
  personalAgilityCanvas: PersonalAgilityCanvas,
};

const App = withAuthInfo(({ isLoggedIn, user, themeMode, toggleTheme }) => {
  const [isSubscriber, setIsSubscriber] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // added loading state

  useEffect(() => {
    if (process.env.REACT_APP_DEBUG) {
      localStorage.debug = process.env.REACT_APP_DEBUG;
    }
  }, []);

  useEffect(() => {
    async function fetchStatusAndUpdateSession() {
      if (isLoggedIn) {
        try {
          const data = await getStatus(user.email);
          setIsSubscriber(data.isSubscriber);
        } catch (error) {
          // TODO: convert to toast
        } finally {
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    }

    fetchStatusAndUpdateSession();
  }, [isLoggedIn, user]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Routes>
      <Route path="/" exact element={<LandingPage />} />
      <Route path="/roadmap" exact element={<Roadmap />} />
      <Route path="/about" exact element={<AboutPage />} />
      <Route path="/pricing" exact element={<PricingPage />} />
      <Route path="/pricing/success" exact element={<SuccessPage />} />
      <Route path="/pricing/error" exact element={<ErrorPage />} />

      {isLoggedIn && isSubscriber && (
        <Route
          path="*"
          element={(
            <ChatMemoryProvider user={user}>
              <NavDrawer themeMode={themeMode} toggleTheme={toggleTheme}>
                <Routes>
                  <Route path="/aiassistant/main" exact element={<MainPage />} />
                  {assistants.map(({ slug, externalUrl }) => (
                    <Route
                      key={slug}
                      path={`/aiassistant/${slug}`}
                      element={
                        externalUrl ? (
                          <ExternalLinkWrapper url={externalUrl} />
                        ) : (
                          <SplitScreenLayout
                            slugComponent={createElement(assistantComponents[slug])}
                          />
                        )
                      }
                    />
                  ))}
                  <Route path="/home" exact element={<ContentPage />} />
                  <Route path="/learn" exact element={<ContentPage />} />
                  <Route path="/chateai" exact element={<ChatEAI />} />
                  <Route path="/askcoachmarty" exact element={<AskCoachMarty user={user} />} />
                  <Route path="/help" element={<LandingPage />} />
                  <Route path="/admin" element={<Admin userEmail={user.email} userId={user.userId} />} />
                  <Route path="/librarian" element={<Librarian />} />
                </Routes>
              </NavDrawer>
            </ChatMemoryProvider>
          )}
        />
      )}
      {!isLoggedIn && !isSubscriber && <Route path="*" element={<NotAuthorizedPage />} />}
    </Routes>
  );
});

export default App;
