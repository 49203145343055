// utils/chatReducer.js
const chatReducer = (state, action) => {
  switch (action.type) {
    case 'SET_LOADING':
      return { ...state, isLoading: action.payload };
    case 'SET_HISTORY':
      return { ...state, chatHistory: action.payload };
    case 'ADD_MESSAGE':
      return { ...state, chatHistory: [...state.chatHistory, action.payload] };
    case 'UPDATE_MESSAGE':
      return {
        ...state,
        chatHistory: state.chatHistory.map((msg) => (msg.id === action.payload.id ? action.payload : msg)),
      };
    case 'UPDATE_LAST_AI_MESSAGE': {
      const updatedChatHistory = [...state.chatHistory];
      updatedChatHistory[updatedChatHistory.length - 1] = action.payload;
      return { ...state, chatHistory: updatedChatHistory };
    }
    case 'SET_ERROR':
      return { ...state, error: action.payload };
    case 'SET_SESSION_INFO':
      return {
        ...state,
        historyId: action.payload.historyId,
        lens: action.payload.lens,
      };
    case 'SET_HISTORY_ID':
      return { ...state, historyId: action.payload };
    case 'SET_SELECTED_SESSION':
      return { ...state, selectedSessionId: action.payload };
    case 'CLEAR_SELECTED_SESSION':
      return { ...state, selectedSessionId: null };
    case 'SET_LENS_NAME':
      return { ...state, lens: action.payload };
    case 'SET_USER_CHAT_SESSIONS':
      return { ...state, userChatSessions: action.payload };
    case 'ADD_NEW_SESSION':
      return {
        ...state,
        userChatSessions: [...state.userChatSessions, action.payload], // Add new session to the list
      };
    case 'RENAME_SESSION':
      return {
        ...state,
        userChatSessions: state.userChatSessions.map((session) => (session.session_id === action.payload.sessionId
          ? { ...session, metadata: { ...session.metadata, sessionName: action.payload.newName } }
          : session)),
      };
    case 'DELETE_SESSION':
      return {
        ...state,
        userChatSessions: state.userChatSessions.filter((session) => session.session_id !== action.payload.sessionId),
      };
    case 'SET_SEARCH_QUERY':
      return { ...state, searchQuery: action.payload };
    default:
      return state;
  }
};
export default chatReducer;

