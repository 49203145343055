import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { faqs } from '../../constants';
import FAQItem from './FAQItem';

const FAQ = () => {
  const [open, setOpen] = useState(false);
  const toggle = (index) => {
    if (open === index) {
      // The return keyword is used here
      return setOpen(false);
    }
    // And the return keyword is used here as well
    return setOpen(index);
  };
  return (
    <Box
      id="faq"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        py: 2,
      }}
    >
      <Typography variant="h3" sx={{ color: 'green', fontWeight: 700, mb: 2 }}>Welcome to Evergreen AI!</Typography>
      <Box sx={{ px: 2, maxWidth: 800 }}>
        {faqs.map((faq, index) => (
          <FAQItem
            key={index}
            open={index === open}
            question={faq.question}
            answer={faq.answer}
            toggle={() => toggle(index)}
          />
        ))}
      </Box>
    </Box>
  );
};

export default FAQ;
