import React from 'react';
import { Box } from '@mui/material';
import { Stats, Business, FAQ } from '.';
import Header from './Header';

const LandingPage = () => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
    }}
  >
    <Box sx={{ width: '100%' }}>
      <Header />
    </Box>
    <Box sx={{ width: '100%' }}>
      <Business />
    </Box>
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', paddingX: 2 }}>
      <Stats />
    </Box>
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', paddingX: 2 }}>
      <FAQ />
    </Box>
  </Box>
);

export default LandingPage;
