// Utility function to append time parameter to URLs based on domain
export const formatUrlWithTime = (url, start) => {
  const urlObj = new URL(url);
  const domain = urlObj.hostname;

  if (domain.includes('youtube.com')) {
    return `${url}&t=${Math.floor(start)}`;
  } if (domain.includes('soundcloud.com')) {
    const hours = Math.floor(start / 3600);
    const minutes = Math.floor((start % 3600) / 60);
    const seconds = Math.floor(start % 60);
    return `${url}#t=${hours}h${minutes}m${seconds}s`;
  }

  return url; // Default case if no specific domain matched
};

export const formatTime = (start) => {
  const hours = Math.floor(start / 3600);
  const minutes = Math.floor((start % 3600) / 60);
  const seconds = Math.floor(start % 60);
  return `${hours}h${minutes}m${seconds}s`;
};
