import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const RenameDialog = ({ open, onClose, onRename, session }) => {
  const [newName, setNewName] = useState('');

  const handleRename = () => {
    onRename(newName, session.sessionId);
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Rename Chat Session</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Enter a new name for your chat session.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="New Name"
          type="text"
          fullWidth
          variant="standard"
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleRename}>Rename</Button>
      </DialogActions>
    </Dialog>
  );
};

export default RenameDialog;
