// utils/useChatSocket.js
import { useEffect, useMemo } from 'react';
import { io } from 'socket.io-client';

const useChatSocket = (serverUrl, historyId, user, dispatch) => {
  const socket = useMemo(() => io(serverUrl, { autoConnect: false }), [serverUrl]);

  useEffect(() => {
    // This ensures that the socket is connected only once, when we have a historyId.
    if (!historyId) return undefined;

    socket.auth = {
      sessionID: historyId,
      userID: user.userId,
      username: user.email,
    };
    socket.connect();

    socket.on('updateMessage', (message, callback) => {
      if (message.isPlaceholder) {
        dispatch({ type: 'UPDATE_LAST_AI_MESSAGE', payload: message });
      } else {
        dispatch({ type: 'ADD_MESSAGE', payload: message });
      }
      if (typeof callback === 'function') {
        callback({ status: 'received' });
      }
    });

    socket.on('connect_error', (err) => {
      if (err.message === 'invalid username') {
        // TODO: convert to TOAST console.log('Invalid username'); // TODO: convert to toast
      }
    });

    return () => {
      socket.off('updateMessage');
      socket.disconnect();
    };
  }, [historyId, socket, dispatch]);

  return socket;
};

export default useChatSocket;
