import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { Box, Grid, Typography, TextField, InputAdornment } from '@mui/material';
import FieldComponent from './FieldComponent';
import VideoIconPopup from '../../VideoCardBox/VideoIconPopup';
import {
  calculateAvailableWorkingDays,
  calculateTotalProductiveHours,
  calculateScrumTime,
  calculateMaximumProductiveHours,
  calculateDailyStandups,
  calculateProductBacklogRefinement,
  calculateVacationHours,
  calculateNonScrumMeetingHours,
  calculateInterruptHours,
} from './utils/calculations';

const initialValues = {
  weeksPerSprint: 0,
  availableWorkingDays: 0,
  hoursPerDay: 0,
  percentageOfTime: 0,
  sprintPlanning: 0,
  dailyStandups: 0,
  sprintReview: 0,
  sprintRetrospective: 0,
  productBacklogRefinement: 0,
  nonScrumMeetings: 0,
  vacationDays: 0,
  interrupts: 0,
  totalProductiveHours: 0,
  maximumProductiveHours: 0,
};

const SprintCapacityForm = () => (
  <Formik
    initialValues={initialValues}
    onSubmit={(values) => {
      console.log(JSON.stringify(values, null, 2));
    }}
  >
    {({ values, setFieldValue }) => {
      useEffect(() => {
        const availableWorkingDays = calculateAvailableWorkingDays(values.weeksPerSprint);
        setFieldValue('availableWorkingDays', availableWorkingDays);

        setFieldValue('sprintPlanning', values.weeksPerSprint * 2);
        setFieldValue('sprintReview', values.weeksPerSprint * 1);
        setFieldValue('sprintRetrospective', values.weeksPerSprint * 0.75);
      }, [values.weeksPerSprint, setFieldValue]);

      useEffect(() => {
        const dailyStandups = calculateDailyStandups(values.availableWorkingDays);
        setFieldValue('dailyStandups', dailyStandups);

        const productBacklogRefinement = calculateProductBacklogRefinement(values.availableWorkingDays);
        setFieldValue('productBacklogRefinement', productBacklogRefinement);
      }, [values.availableWorkingDays, setFieldValue]);

      useEffect(() => {
        const totalProductiveHours = calculateTotalProductiveHours(values);
        setFieldValue('totalProductiveHours', totalProductiveHours);
      }, [values.hoursPerDay, values.percentageOfTime, values.availableWorkingDays, setFieldValue]);

      useEffect(() => {
        const totalProductiveHours = calculateTotalProductiveHours(values);
        const scrumTime = calculateScrumTime(values);
        let maximumProductiveHours = calculateMaximumProductiveHours(totalProductiveHours, scrumTime);
        const vacationHours = calculateVacationHours(values.vacationDays, values.hoursPerDay);
        maximumProductiveHours -= vacationHours;

        const nonScrumMeetingHours = calculateNonScrumMeetingHours(values.nonScrumMeetings);
        maximumProductiveHours -= nonScrumMeetingHours;

        const interruptHours = calculateInterruptHours(values.interrupts);
        maximumProductiveHours -= interruptHours;

        setFieldValue('maximumProductiveHours', maximumProductiveHours);
      }, [
        values.totalProductiveHours,
        values.vacationDays,
        values.hoursPerDay,
        values.nonScrumMeetings,
        values.interrupts,
        setFieldValue,
      ]);

      const scrumTime = calculateScrumTime(values);

      return (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 1,
                  borderRadius: 1,
                  color: 'secondary',
                  boxShadow: 2,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Estimate of Time Available
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={6}>
                    <FieldComponent name="weeksPerSprint" label="Weeks per Sprint" sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                    <FieldComponent name="availableWorkingDays" label="Available Working Days" readOnly sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                      <FieldComponent name="hoursPerDay" label="Hours per day of productive work" sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                      <VideoIconPopup videoUrl="https://vimeo.com/969115148/5336a820ce?share=copy" />
                    </Box>
                    <Field name="percentageOfTime">
                      {({ field, form }) => (
                        <TextField
                          {...field}
                          type="number"
                          label="Percentage of Time for Project"
                          onChange={(e) => {
                            const { value } = e.target;
                            form.setFieldValue(field.name, value);
                          }}
                          InputProps={{
                            endAdornment: values.percentageOfTime ? <InputAdornment position="end">%</InputAdornment> : null,
                          }}
                          sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }}
                        />
                      )}
                    </Field>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FieldComponent name="sprintPlanning" label="Sprint Planning Hours" readOnly sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                    <FieldComponent name="dailyStandups" label="Daily Standups Hours" readOnly sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                    <FieldComponent name="sprintReview" label="Sprint Review Hours" readOnly sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                    <FieldComponent name="sprintRetrospective" label="Sprint Retrospective Hours" readOnly sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                    <FieldComponent name="productBacklogRefinement" label="Product Backlog Refinement Hours" readOnly sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  padding: 1,
                  borderRadius: 1,
                  color: 'secondary',
                  boxShadow: 2,
                }}
              >
                <Typography variant="h6" gutterBottom>
                  Interruptions
                </Typography>
                <Grid container spacing={1}>
                  <Grid item xs={12} sm={4}>
                    <FieldComponent name="nonScrumMeetings" label="Non-Scrum Meetings Hours" sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FieldComponent name="vacationDays" label="Vacation/Sick Days" sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FieldComponent name="interrupts" label="Emergency Fixes/Interrupts" sx={{ color: 'black', backgroundColor: 'white', borderRadius: 1 }} />
                  </Grid>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  marginTop: 2,
                  padding: 1,
                  borderRadius: 1,
                  background: 'linear-gradient(to right, #6a11cb, #2575fc)',
                  color: 'white',
                  boxShadow: 2,
                }}
              >
                <div>Total Productive Hours: {values.totalProductiveHours ? values.totalProductiveHours.toFixed(2) : '0.00'}</div>
                <div>Total Scrum Ceremony Time: {scrumTime.toFixed(2)}</div>
                <div>Maximum Possible Productive Hours: {values.maximumProductiveHours ? values.maximumProductiveHours.toFixed(2) : '0.00'}</div>
              </Box>
            </Grid>
          </Grid>
        </Form>
      );
    }}
  </Formik>
);

export default SprintCapacityForm;
