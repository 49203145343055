
import {
  // bdd,
  drunkenpmSprintCapacity,
  problemStatement,
  applicationcostcalculator,
  sprintgoals,
} from '../../assets';

const assistants = [
  {
    cardImage: problemStatement,
    title: 'Problem Statement',
    // eslint-disable-next-line max-len
    desc: 'A problem statement is a concise description of an issue that needs to be addressed or a condition that needs to be improved.',
    slug: 'chateai',
    category: 'COPILOT',
    lensName: 'Problem Statement',
  },
  {
    cardImage: sprintgoals,
    title: 'Sprint Goals',
    // eslint-disable-next-line max-len
    desc: 'Create a sprint goal based on a list of your sprint backlog items',
    slug: 'chateai',
    category: 'COPILOT',
    lensName: 'Sprint Goals',
  },
  {
    cardImage: drunkenpmSprintCapacity,
    title: 'Drunkenpm Sprint Capacity Calculator',
    // eslint-disable-next-line max-len
    desc: 'Use this tool to calculate your individual sprint commitment in ideal engineering hours.',
    slug: 'sprintcapacity',
    category: 'WORKSHEET',
    lensName: '',
  },
  {
    cardImage: applicationcostcalculator,
    title: 'ClearlyAgile Application Cost Calcultor',
    // eslint-disable-next-line max-len
    desc: 'Want a rough idea on the cost of bulding custom software? Use our pricing calculate to get an estimate.',
    slug: 'applicationcostcalculator',
    externalUrl: 'http://calc.clearlyagile.com',
    category: 'WORKSHEET',
    lensName: '',
  },
];

export default assistants;
