import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        textAlign: 'center',
        bgcolor: '#f5f5f5',
      }}
    >
      <SentimentVeryDissatisfiedIcon color="error" style={{ fontSize: 100 }} />

      <Typography variant="h4" gutterBottom>
        Oops! Something Went Wrong.
      </Typography>

      <Typography variant="h6" gutterBottom>
        We had a little hiccup processing your payment.
      </Typography>

      <Typography variant="body1" sx={{ mb: 4 }}>
        But don&apos;t worry, our top-notch team is already on it! Please try again shortly.
        Remember, at Evergreen AI we&apos;re all about &quot;Ask. Learn. Do.&quot; and sometimes that includes a little hiccup!
      </Typography>

      <Button variant="contained" color="primary" onClick={goToHome}>
        Back to Home
      </Button>
    </Box>
  );
};

export default ErrorPage;
