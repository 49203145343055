import React from 'react';
import Slider from 'react-slick';
import { IconButton } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const NextArrow = ({ onClick }) => (
  <IconButton onClick={onClick} variant="contained" size="small" sx={{ position: 'absolute', top: '50%', right: 24, transform: 'translateY(-50%)', zIndex: 1 }}>
    <NavigateNextIcon />
  </IconButton>
);

const PrevArrow = ({ onClick }) => (
  <IconButton onClick={onClick} variant="contained" size="small" sx={{ position: 'absolute', top: '50%', left: -16, transform: 'translateY(-50%)', zIndex: 1 }}>
    <NavigateBeforeIcon />
  </IconButton>
);

const defaultSettings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 3,
  initialSlide: 0,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 1,
      },
    },
  ],
};

const CarouselSlider = ({ children, settings = defaultSettings }) => (
  <Slider {...settings}>
    {children}
  </Slider>
);

export default CarouselSlider;
