import React from 'react';
import { Box } from '@mui/material';
// import Videos from '../ActivityGuide/Videos';
// import Courses from '../LMS/Courses';
import AiAssistants from '../AiAssistant/AiAssistants';
// import SearchBar from '../ActivityGuide/utils/SearchBar';

// import SimpleSlider from './SimpleSlider';

const ContentPage = ({ showAiAssistant = true }) => (
  <Box
    maxWidth={1024}
    sx={{ mt: 12, ml: 16 }}
  >
    {showAiAssistant && <AiAssistants />}
  </Box>
);
export default ContentPage;
