import React from 'react';
import Message from './Message';

const HumanMessage = ({ avatar, children }) => (
  <Message avatar={avatar} transmission="human" className="human-message">
    {children}
  </Message>
);

export default HumanMessage;
