import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ReactPlayer from 'react-player';
import Emoji from 'a11y-react-emoji';
import { features } from '../../constants';

const FeatureCard = ({ icon, title, content }) => (
  <Box sx={{ display: 'flex', flexDirection: 'row', mb: 4, backgroundColor: 'feature-card' }}>
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 64, height: 64, borderRadius: '50%', backgroundColor: 'dimBlue' }}>
      <img src={icon} alt="icon" className="myImageClass" />
    </Box>
    <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', ml: 3 }}>
      <Typography component="h4" variant="h6" sx={{ fontWeight: 'bold', color: 'dimWhite', mb: 1 }}>
        {title}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: 'normal', color: 'dimWhite' }}>
        {content}
      </Typography>
    </Box>
  </Box>
);

const Business = () => {
  const navigate = useNavigate();
  const handleScheduleClick = () => {
    window.open('https://usemotion.com/meet/marty-bradley/SimpleAI-Explore?d=30', '_blank');
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center', maxWidth: 1200, margin: '0 auto', p: 4 }}>
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start' }}>
          <Box id="evergreencopilot" sx={{ p: 2, display: 'flex', justifyContent: 'center', mr: 4 }}>
            <Box sx={{ maxWidth: '100%' }}>
              <ReactPlayer
                url="https://vimeo.com/914510070/1a1cb3b636?share=copy"
                config={{
                  vimeo: {
                    playerOptions: {
                      controls: false,
                      byline: false,
                      portrait: false,
                      title: false,
                      speed: false,
                      transparent: false,
                      loop: true,
                      autoplay: true,
                      muted: true,
                      quality: '1080p',
                      dnt: true,
                    },
                  },
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: 'flex', gap: 2, mt: 10 }}>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#FF9900', '&:hover': { backgroundColor: '#FF9900' } }}
            onClick={() => navigate('/pricing')}
          >
            Get Started, Now! <Emoji symbol="👉" /> <Emoji symbol="👉" />
          </Button>
          <Button
            variant="contained"
            sx={{ backgroundColor: '#00A6FF', '&:hover': { backgroundColor: '#007ACC' } }}
            onClick={handleScheduleClick}
          >
            Schedule Time to Discuss Training
          </Button>
        </Box>
      </Box>
      <Box sx={{ flex: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          {features.map((feature, index) => (
            <FeatureCard key={feature.id} {...feature} index={index} />
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Business;
