import { createSlice } from '@reduxjs/toolkit';

export const courseCategory = createSlice({
  name: 'courseCategory',
  initialState: {
    courseCategoryId: '',
    page: 1,
    searchQuery: '',
  },
  reducers: {
    selectCourseCategory: (state, action) => {
      state.courseCategoryId = action.payload;
      state.searchQuery = '';
    },
    searchCourse: (state, action) => {
      state.searchQuery = action.payload;
    },
  },
});

export const { selectCourseCategory, searchCourse } = courseCategory.actions;
export default courseCategory.reducer;
