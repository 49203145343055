import React, { useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField, Button, RadioGroup, FormControlLabel, FormControl, FormLabel, Radio, Box, Grid } from '@mui/material';

import { updateAdminUserSettings, getAdminUserSettings } from './AdminService';

const Admin = ({ userEmail, userId }) => {
  // Fetches the user settings and sets the form values, if they don't exist then the default values are used
  const fetchAndSetUserSettings = async (setFieldValue) => {
    const userSettings = await getAdminUserSettings(userId);
    if (userSettings) {
      const { almAPIToken, almTool, almUserId } = userSettings;
      setFieldValue('jiraEmail', almUserId || userEmail);
      setFieldValue('jiraApiToken', almAPIToken);
      setFieldValue('almTool', almTool || 'none');
    }
  };

  return (
    <Formik
      initialValues={{ email: userEmail, almTool: 'none', jiraEmail: userEmail, jiraApiToken: '' }}
      onSubmit={(values) => {
        updateAdminUserSettings(userId, values);
      }}
    >
      {({ values, handleChange, handleSubmit, setFieldValue }) => {
        useEffect(() => {
          fetchAndSetUserSettings(setFieldValue);
        }, [setFieldValue]);

        return (
          <Form onSubmit={handleSubmit} style={{ maxWidth: '500px', margin: 'auto', marginTop: '64px', marginLeft: '240px' }}>
            <Field
              name="email"
              as={TextField}
              label="Email Address"
              type="email"
              fullWidth
              margin="normal"
              onChange={handleChange}
              disabled // Makes the field read-only
              value={values.email}
            />

            <FormControl component="fieldset" margin="normal">
              <FormLabel component="legend">Project Management Tool</FormLabel>
              <Field
                name="almTool"
                as={RadioGroup}
                row
                aria-label="alm-tool"
                onChange={handleChange}
              >
                <FormControlLabel value="none" control={<Radio />} label="None" />
                <FormControlLabel value="jira" control={<Radio />} label="JIRA" />
              </Field>
            </FormControl>

            {values.almTool === 'jira' && (
              <>
                <Field
                  name="jiraEmail"
                  as={TextField}
                  label="JIRA Email (Change if different from Evergreen AI email)"
                  type="email"
                  fullWidth
                  margin="normal"
                  onChange={handleChange}
                />
                <Grid container alignItems="flex-end" spacing={1}>
                  <Grid item xs>
                    <Field
                      name="jiraApiToken"
                      as={TextField}
                      label="JIRA API Token"
                      type="password" // Using password type to hide the API token input
                      fullWidth
                      margin="normal"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item>
                    <a
                      href="https://id.atlassian.com/manage-profile/security/api-tokens"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: 'blue', textDecoration: 'underline' }}
                    >
                      How to generate API key?
                    </a>
                  </Grid>
                </Grid>
              </>
            )}

            <Box mt={2}>
              <Button type="submit" variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </Form>
        );
      }}
    </Formik>
  );
};

export default Admin;
