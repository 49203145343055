import React from 'react';
import { withAuthInfo } from '@propelauth/react';
import { Box, Avatar, Typography } from '@mui/material';
import { useChatMemory } from '../ChatMemoryProvider/ChatMemoryProvider';
import HumanMessage from './HumanMessage';
import AiMessage from './AiMessage';
import { copilotavatar } from '../../assets';
import VideoIconPopup from '../VideoCardBox/VideoIconPopup';

const AiChatBox = withAuthInfo((user) => {
  const { chatHistory, isInitialized } = useChatMemory();
  /*
  if (!isInitialized) {
    return <Typography>Loading...</Typography>;
  }
    */

  // https://vimeo.com/972283972
  if (isInitialized && chatHistory.length === 0) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        <VideoIconPopup videoUrl="https://vimeo.com/972283972/a95e39f9b2?share=copy" />
        <Typography>Start typing below, or paste your text using ctrl+v</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ padding: 1 }}>
      {chatHistory.map((message, index) => (
        message.type.toLowerCase() === 'human' ? (
          <HumanMessage key={index} avatar={<Avatar src={user.user.pictureUrl} />}>
            {message.content}
          </HumanMessage>
        ) : (
          <AiMessage key={index} avatar={<Avatar src={copilotavatar} />}>
            {message.content}
          </AiMessage>
        )
      ))}
    </Box>
  );
});

export default AiChatBox;
