import React, { useState, useCallback, useEffect } from 'react';
import { FormControl, OutlinedInput, InputAdornment, IconButton, Box, Grid, LinearProgress, Typography } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { withAuthInfo } from '@propelauth/react';
import { useTheme } from '@mui/material/styles';
import SendIcon from '@mui/icons-material/Send';
import AiChatBox from '../AiChatBox/AiChatBox';
import { useChatMemory } from '../ChatMemoryProvider/ChatMemoryProvider';
import fetchUsingActiveLens from '../../services/lensService';

const AskCoachMarty = withAuthInfo(({ accessToken, userClass }) => {
  const theme = useTheme();
  const { historyId, lens, dispatch } = useChatMemory();
  // const [input, setInput] = useState('');
  const [question, setQuestion] = useState('');
  const [loading, setLoading] = useState(false);
  const [placeholder, setPlaceholder] = useState('');

  useEffect(() => {
    if (lens === 'Product Manager Copilot') {
      setPlaceholder('Epic Title: brief descriptive title, can be changed later.');
    } else if (lens === 'Ask the Coaches') {
      setPlaceholder('Ask the coaches a question');
    } else {
      setPlaceholder('');
    }
  }, [lens]);

  const handleSubmit = useCallback(async (e) => {
    e.preventDefault();

    const humanMessage = {
      type: 'human',
      content: question,
      timestamp: new Date().toISOString(),
    };
    dispatch({ type: 'ADD_MESSAGE', payload: humanMessage });

    const aiPlaceholder = {
      id: Date.now(),
      type: 'ai',
      content: '...',
      timestamp: new Date().toISOString(),
    };
    dispatch({ type: 'ADD_MESSAGE', payload: aiPlaceholder });

    try {
      // console.log('fetchUsingActiveLens_BEFORE');
      await fetchUsingActiveLens(lens, historyId, question, accessToken, userClass);
    } catch (error) {
      // Handle error
    }

    setQuestion('');
    setLoading(false);
  }, [historyId, question]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  return (
    <Grid
      container
      direction="column"
      sx={{
        height: '100vh',
        mt: 12,
        maxHeight: '740px',
        maxWidth: '800px',
      }}
    >
      <Grid
        item
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
          px: 2,
          flex: 1,
        }}
      >
        <AiChatBox />
      </Grid>
      <Grid item sx={{ minWidth: '300px', flexShrink: 0 }}>
        {loading && <LinearProgress sx={{ marginTop: 2 }} />}
        <Box
          sx={{
            padding: 2,
            backgroundColor: theme.palette.common.default,
            borderTop: '1px solid',
            borderColor: theme.palette.divider,
            maxWidth: '100%',
          }}
        >
          <form onSubmit={handleSubmit}>
            <FormControl variant="outlined" fullWidth>
              <Box display="flex" alignItems="center" />
              <Tooltip title="Type a new message here or interact with the chat above.">
                <OutlinedInput
                  multiline
                  maxRows={4}
                  value={question}
                  onChange={(e) => setQuestion(e.target.value)}
                  onKeyDown={handleKeyDown}
                  placeholder={placeholder}
                  endAdornment={(
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        disabled={question === ''}
                        type="submit"
                        color="primary"
                      >
                        <SendIcon />
                      </IconButton>
                    </InputAdornment>
                  )}
                />
              </Tooltip>
            </FormControl>
          </form>
          <Typography variant="body1" align="left" color="green" gutterBottom sx={{ mt: 0, fontWeight: 'bold' }}>
            <span>{lens}</span>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
});

export default AskCoachMarty;
