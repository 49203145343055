import React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import Rating from '@mui/material/Rating';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const Course = ({ course }) => (
  <Card
    sx={{
      maxWidth: 350,
      m: 2,
      height: 350,
      border: 1,
      borderRadius: 1,
      borderColor: '#d3d3d3', // light grey
      transition: '0.3s',
      // background: 'linear-gradient(to right, #6a11cb, #2575fc)',
      // color: 'white',
      '&:hover': {
        transform: 'scale(1.05)',
        boxShadow: '5px 5px 15px rgba(0, 0, 0, 0.3)',
      },
    }}
  >
    <CardActionArea component={RouterLink} to={`https://learn.evergreenagile.com/courses/${course.slug}`} target="_blank">
      <CardMedia
        component="img"
        height="175"
        image={course.card_image_url}
        alt={course.name}
      />
      <CardContent sx={{ flexGrow: 1 }}>
        <Typography gutterBottom variant="body2" component="div" color="text.secondary" sx={{ fontSize: 12, fontWeight: 'bold' }}>
          {course.name}
        </Typography>
        <Typography noWrap textOverflow="ellipsis" variant="body2" color="text.secondary" sx={{ fontSize: 12 }}>
          {course.description}
        </Typography>
      </CardContent>
    </CardActionArea>
    <CardActions disableSpacing>
      <Typography component="legend" sx={{ fontSize: 12, color: 'secondary' }}>Librarian Rating</Typography>
      <Rating name="librarian-rating" size="small" defaultValue={5} precision={0.5} sx={{ color: 'secondary' }} />
    </CardActions>
  </Card>
);

export default Course;
