/* eslint-disable import/no-cycle */
import React from 'react';
import { Box, Typography } from '@mui/material';
import Assistant from './Assistant';
import CarouselSlider from '../AppLayout/CarouselSlider';

const getLink = (slug, category, externalUrl) => {
  if (category.startsWith('VOTE')) {
    return externalUrl;
  } if (category.startsWith('WORKSHEET')) {
    return externalUrl || `/aiassistant/${slug}`;
  }
  return `/${slug}`;
};

const AssistantList = ({ assistants }) => (
  <Box>
    {/* Title for the video list */}
    <Typography variant="h6" sx={{ mt: 2 }}>
      Agile AI Copilots
    </Typography>

    <CarouselSlider>
      {assistants.map(({ cardImage, title, desc, slug, category, externalUrl, lensName }, index) => (
        <Assistant
          key={index} // Added a key prop here using the index of the array
          cardImage={cardImage}
          title={title}
          desc={desc}
          link={getLink(slug, category, externalUrl)}
          externalLink={category.startsWith('VOTE')}
          category={category}
          lensName={lensName}
        />
      ))}
    </CarouselSlider>
  </Box>
);

export default AssistantList;
