// epicBriefReducer.js

import { createSlice } from '@reduxjs/toolkit';

export const epicBrief = createSlice({
  name: 'epicBrief',
  initialState: {
    businessOutcome: '',
    successCriteria: '',
    persona: '',
    context: '',
    businessCapabilities: '',
  },
  reducers: {
    saveEpicBrief: (state, action) => {
      state.businessOutcome = action.payload.businessOutcome;
      state.successCriteria = action.payload.successCriteria;
      state.persona = action.payload.persona;
      state.context = action.payload.context;
      state.businessCapabilities = action.payload.businessCapabilities;
    },
  },
});

export const { saveEpicBrief } = epicBrief.actions;

export default epicBrief.reducer;
