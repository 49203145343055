import React from 'react';
import Message from './Message';

const AiMessage = ({ avatar, children }) => (
  <Message avatar={avatar} transmission="ai" className="ai-message">
    {children}
  </Message>
);

export default AiMessage;

