import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import Grid from '@mui/material/Grid';
import Brightness4Icon from '@mui/icons-material/Brightness4';
import Brightness7Icon from '@mui/icons-material/Brightness7';
import Tooltip from '@mui/material/Tooltip';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssistantIcon from '@mui/icons-material/Assistant';
// import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
// import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import { withAuthInfo } from '@propelauth/react';

const menuItems = [
  { text: 'Home', icon: <HomeOutlinedIcon />, path: '/home', tooltip: 'Navigate to the home page' },
  { text: 'Copilots', icon: <AssistantIcon />, path: '/home', tooltip: 'Access AI Copilots' },
  // { text: 'Settings', icon: <AdminPanelSettingsIcon />, path: '/admin', tooltip: 'User Application Settings' },
  // { text: 'Settings', icon: <LocalLibraryIcon />, path: '/librarian', tooltip: 'Librarian Dashboard' },
];

const handleAvatarClick = () => {
  const propelAuthUrl = process.env.REACT_APP_PROPELAUTH_AUTH_URL;
  window.location.href = `${propelAuthUrl}/account`;
};

const drawerMixin = (theme) => ({
  width: `calc(${theme.spacing(8)} + 1px)`,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  boxSizing: 'border-box',
});

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme }) => ({
    ...drawerMixin(theme),
    '& .MuiDrawer-paper': drawerMixin(theme),
  }),
);

const NavDrawer = withAuthInfo(({ children, themeMode = 'dark', toggleTheme, ...props }) => (
  <Box>
    <Drawer variant="permanent">
      <Divider />
      <List>
        {menuItems.map((menuItem) => (
          <React.Fragment key={menuItem.text}>
            <ListItem disablePadding sx={{ display: 'block' }}>
              <Tooltip title={menuItem.tooltip} arrow placement="right">
                <ListItemButton
                  component={RouterLink}
                  to={menuItem.path}
                  sx={{
                    minHeight: 48,
                    justifyContent: 'center',
                    px: 2.5,
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      justifyContent: 'center',
                    }}
                  >
                    {menuItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={menuItem.text} sx={{ opacity: 0 }} />
                </ListItemButton>
              </Tooltip>
            </ListItem>
            {menuItem.text === 'Settings' && <Divider />}
          </React.Fragment>
        ))}
      </List>
      <Box sx={{ flexGrow: 1 }} />
      <Tooltip title="Account information or logout" arrow placement="right">
        <IconButton onClick={handleAvatarClick} sx={{ p: 2 }}>
          <Avatar alt={props.user.firstName} src={props.user.pictureUrl} />
        </IconButton>
      </Tooltip>
      <IconButton
        onClick={toggleTheme}
        color="inherit"
        sx={{ margin: 2 }}
      >
        {themeMode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
      </IconButton>
    </Drawer>
    <Grid container direction="row" spacing={2}>
      {children}
    </Grid>
  </Box>
));

export default NavDrawer;
