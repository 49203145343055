// chatService.js
import { v4 as uuidv4 } from 'uuid';

const appUrl = process.env.REACT_APP_API_URL;

export const initializeChatMemory = async (historyId, email) => {
  const response = await fetch(`${appUrl}/api/chain/chatMemory`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ historyId, email }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Server error');
  }

  const chatHistory = await response.json();
  return chatHistory;
};

export const createChatMemory = async (user, lensName) => {
  const response = await fetch(`${appUrl}/api/chain/createMemory`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user, lensName }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Server error');
  }
  const data = await response.json();
  const { session } = data;
  return session;
};

/**
 * Renames the chat memory with the given historyId to the newName provided.
 * @param {string} historyId - The ID of the chat history to rename
 * @param {string} newName - The new name to rename the chat history to
 */
export const renameChatMemory = async (historyId, newName) => {
  console.log('===CHATSERVICE===renameChatMemory: ', historyId, newName);
  const response = await fetch(`${appUrl}/api/chain/renameMemory`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ historyId, newName }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Server error');
  }

  return { success: true };
};

/**
 * Deletes the chat memory with the given historyId.
 * @param {string} historyId - The ID of the chat history to delete
 */
export const deleteChatMemory = async (historyId) => {
  const response = await fetch(`${appUrl}/api/chain/deleteMemory`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ historyId }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Server error');
  }

  return { success: true };
};

export const clearChatMemory = async (historyId, userId) => {
  const response = await fetch(`${appUrl}/api/chain/clearMemory`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ historyId, userId }),
  });

  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.message || 'Server error');
  }

  return { success: true };
};

export const getChatHistoryID = async (userId) => {
  const response = await fetch(`${appUrl}/api/customer/getChatHistoryID`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId }),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch chatHistoryID');
  }

  return response.json();
};

export const getUserChatSessions = async (user) => {
  const response = await fetch(`${appUrl}/api/chain/getUserChatSessions`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ user }),
  });
  if (!response.ok) {
    throw new Error('Failed to fetch chat sessions');
  }

  return response.json();
};

export const updateChatHistoryID = async (userId, chatHistoryID) => {
  const response = await fetch(`${appUrl}/api/customer/updateChatHistoryID`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ userId, chatHistoryID }),
  });

  if (!response.ok) {
    throw new Error('Failed to fetch chatHistoryID');
  }

  return response.json();
};

export const newChatHistoryID = async (userId) => {
  const chatHistoryID = uuidv4();
  await updateChatHistoryID(userId, chatHistoryID);
  return chatHistoryID;
};
