import React from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { useTheme } from '@mui/material/styles';
import Courses from '../LMS/Courses';
import VideoSearchPage from './VideoSearchPage';
// import ActivityGuide from './ActivityGuide';
// import { useDocumentStore } from '../../app/zustand/useDocumentStore';

const LearnTabs = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState('1');
  // const documents = useDocumentStore((state) => state.documents);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div style={{ marginTop: '32px' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <div style={{ ...theme.mixins.toolbar }} />
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Streaming" value="1" />
            <Tab label="Courses" value="2" />
          </TabList>
        </Box>
        <TabPanel value="1">
          <Box sx={{ maxHeight: '100%', overflow: 'auto' }}>
            <VideoSearchPage />
          </Box>
        </TabPanel>
        <TabPanel value="2">
          <Box sx={{ maxHeight: '100%', overflow: 'auto' }}>
            <Courses />
          </Box>
        </TabPanel>
      </TabContext>
    </div>
  );
};

export default LearnTabs;
