import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
// import AskCoachMarty from '../AskCoachMarty/AskCoachMarty';
import LearnTabs from '../ActivityGuide/LearnTabs';

const SplitScreenLayout = ({ slugComponent, showAiAssistant = true }) => {
  const theme = useTheme();
  const [showActivityGuide, setShowActivityGuide] = useState(false);
  // const [showAiAssistant, setShowAiAssistant] = useState(true);

  useEffect(() => {
    if (!showAiAssistant) {
      setShowActivityGuide(false); // Ensure Activity Guide is hidden when AiAssistant is hidden
    }
  }, [showAiAssistant]);

  return (
    <Grid container sx={{ mt: 12, ml: 16 }}>
      <div style={{ ...theme.mixins.toolbar }} />
      {showAiAssistant && (
        <Grid item xs={6}>
          {React.cloneElement(slugComponent, { showActivityGuide, setShowActivityGuide })}
          {showActivityGuide && <LearnTabs />}
        </Grid>
      )}
    </Grid>
  );
};

export default SplitScreenLayout;
