import { Grid } from '@mui/material';
import React from 'react';
import ChatBubble from './ChatBubble';
// import MessageIcon from './MessageIcon';

const Message = ({ avatar, transmission, children }) => (
  <Grid container direction="row" sx={{ alignItems: 'flex-start', spacing: 2 }}>
    <Grid item>
      {avatar}
    </Grid>
    <Grid item xs sx={{ ml: 1 }}>
      <ChatBubble transmission={transmission}>{children}</ChatBubble>
    </Grid>
  </Grid>
);

export default Message;

