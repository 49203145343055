/* eslint-disable import/no-cycle */
import React from 'react';
import { Box, Typography } from '@mui/material';
import Video from './Video';
import CarouselSlider from '../AppLayout/CarouselSlider';

const VideoList = ({ videos, query }) => (
  <Box>
    {/* Title for the video list */}
    <Typography variant="h6" sx={{ mt: 2 }}>
      Hear from your coaches
    </Typography>

    <CarouselSlider>
      {videos.map((video) => <Video video={video} query={query} />)}
    </CarouselSlider>
  </Box>

);

export default VideoList;
