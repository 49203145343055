/* eslint-disable max-len */
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Typography, Box, Grid, Button, Modal } from '@mui/material';
import ActiveCampaignForm from '../Pricing/ActiveCampaignForm';
import fulllogo from '../../assets/images/fulllogo.png';
import founders from '../../assets/images/founders.png';
import challenge from '../../assets/images/challenge.png';

const AboutPage = () => {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const navigate = useNavigate();
  const goToRoot = () => {
    navigate('/');
  };

  const images = [fulllogo, founders, challenge];

  return (
    <Container maxWidth="lg">
      <Box sx={{ flexGrow: 1, overflow: 'hidden', px: 3, py: 4 }}>
        <Typography variant="h4" gutterBottom sx={{ fontStyle: 'italic' }}>
          We know first hand the struggles of being a product manager in a midsized business.
        </Typography>

        {/* Alternating image with text sections */}
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <img src={images[0]} alt="Evergreen AI Logo" style={{ width: '100%', height: '80%' }} />
          </Grid>
          {/* Welcome to Evergreen AI */}
          <Grid item xs={12} md={6}>
            <Typography variant="h4" gutterBottom>
              Welcome to Evergreen AI
            </Typography>
            <Typography variant="body1">
              At Evergreen AI, your success is our mission. We&apos;re a dynamic &apos;learn-by-doing community, committed to nurturing growth, learning, and collaborative knowledge-sharing. Our focus is on providing insightful guidance and practical advice to enhance your business journey.
              <p />
              Our comprehensive range of knowledge-creation tools is designed to offer robust support to your enterprise. Our team, dedicated to continuous learning and skill development, ensures the delivery of up-to-date and effective strategies to help you succeed.
              <p />
              We aim to equip you with innovative, adaptable tools, fostering an environment where your business can flourish in the ever-evolving market landscape.
            </Typography>
          </Grid>

          {/* Our Experience and Approach */}
          <Grid item xs={12} md={6} order={{ xs: 3, md: 2 }}>
            <Typography variant="h4" gutterBottom>
              Our Experience and Approach
            </Typography>
            <Typography variant="body1">
              My cofounder and I bring over seven decades of combined experience in this field. Together, we&apos;ve coached over ten thousand individuals across hundreds of diverse companies, both large and small. Our AI copilots are trained using proven, hands-on techniques drawn from our extensive experience and successes.
              <p />
              These AI copilots possess deep knowledge, but they&apos;re also equipped to consult industry leaders for the most accurate and relevant answers. If they find a valuable research article or video, they&apos;ll pinpoint the exact section relevant to your question, saving you hours of searching. However, as skilled as our copilots are, they don&apos;t know your business as intimately as you do. They can&apos;t fully understand the emotions of your customers or the pressures your bosses face.
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 2, md: 3 }}>
            <img src={images[1]} alt="The Founders" style={{ width: '100%', height: 'auto' }} />
          </Grid>

          {/* The Challenge to You */}
          <Grid item xs={12} md={6} order={{ xs: 4 }}>
            <img src={images[2]} alt="Your Challenge" style={{ width: '100%', height: 'auto' }} />
          </Grid>
          <Grid item xs={12} md={6} order={{ xs: 5 }}>
            <Typography variant="h4" gutterBottom>
              The Challenge to You
            </Typography>
            <Typography variant="body1">
              This brings us to a crucial question: Is it &quot;someday&quot; or &quot;day one&quot; for you? Will you wait to handle your projects, or is today the day you become the tip of the spear? Will it be &quot;Someday&quot; that you discover the potential of AI, or is this &quot;Day One&quot; that you find your sweet spot and slice through your workload?
              <p />
              You strike us as a &quot;Day One&quot; kind of person. So, let&apos;s get to work.
            </Typography>
          </Grid>
        </Grid>
        <Box sx={{ mt: 5 }}>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Contact Us
          </Button>
          <Button variant="outlined" color="primary" sx={{ mr: 2 }} onClick={goToRoot}>
            Home
          </Button>
        </Box>
        <Modal open={open} onClose={handleClose}>
          <ActiveCampaignForm />
        </Modal>
      </Box>
    </Container>
  );
};

export default AboutPage;
