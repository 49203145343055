import React, { useState } from 'react';
import { Box, TextField, Button, Grid, Typography, Paper } from '@mui/material';

const PersonalAgilityCanvas = () => {
  const [inputs, setInputs] = useState({
    vision: '',
    support: '',
    strengths: '',
    metrics: '',
    worries: '',
    gaps: '',
    saboteur: '',
    actions: '',
    accountability: '',
  });

  const [canvasData, setCanvasData] = useState({
    vision: [],
    support: [],
    strengths: [],
    metrics: [],
    worries: [],
    gaps: [],
    saboteur: [],
    actions: [],
    accountability: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };

  const handleAddBullet = (section) => {
    setCanvasData((prevData) => ({
      ...prevData,
      [section]: [...prevData[section], inputs[section]],
    }));
    setInputs((prevInputs) => ({
      ...prevInputs,
      [section]: '',
    }));
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Grid container spacing={2}>
        {Object.keys(canvasData).map((section, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Paper elevation={3} sx={{ padding: 2, minHeight: 200 }}>
              <Typography variant="h6" gutterBottom>
                {section.charAt(0).toUpperCase() + section.slice(1)}
              </Typography>
              <TextField
                name={section}
                value={inputs[section]}
                onChange={handleInputChange}
                placeholder={`Add bullet to ${section}`}
                fullWidth
                sx={{ marginBottom: 2 }}
              />
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddBullet(section)}
                fullWidth
              >
                Add
              </Button>
              <Box sx={{ marginTop: 2 }}>
                {canvasData[section].map((item, idx) => (
                  <Typography key={idx} variant="body1">
                    • {item}
                  </Typography>
                ))}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default PersonalAgilityCanvas;
