import React, { useState } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { AuthProvider } from '@propelauth/react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import GlobalStyles from '@mui/material/GlobalStyles';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import EvergreenTheme from './EvergreenTheme';

import store from './app/store';

import App from './components/App';
import './index.css';

const rootElement = document.getElementById('root');

const globalStyles = {
  '*': {
    boxSizing: 'border-box',
    margin: 0,
    padding: 0,
  },
  'html, body': {
    width: '100%',
    height: '100%',
    fontFamily: 'Poppins, sans-serif',
  },
  body: {
    margin: 0,
    padding: 0,
    backgroundColor: '#f4f4f4',
  },
  '#root': {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
  },
  '.slick-slider': {
    '.slick-list': {
      overflow: 'hidden',
    },
    '.slick-track': {
      display: 'flex',
    },
    '.slick-slide': {
      visibility: 'visible',
      '&.slick-active': {
        display: 'block',
      },
    },
  },
};

const Main = () => {
  const [themeMode, setThemeMode] = useState('light'); // Default to light theme
  const toggleTheme = () => {
    setThemeMode((prevMode) => (prevMode === 'dark' ? 'light' : 'dark'));
  };

  return (
    <AuthProvider authUrl={process.env.REACT_APP_PROPELAUTH_AUTH_URL}>
      <Provider store={store}>
        <Router>
          <ThemeProvider theme={EvergreenTheme(themeMode)}>
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
            <App toggleTheme={toggleTheme} themeMode={themeMode} />
          </ThemeProvider>
        </Router>
      </Provider>
    </AuthProvider>
  );
};

createRoot(rootElement).render(<Main />);
