// calculations.js

/**
 * Function to calculate available working days
 * @param {number} weeksPerSprint - The number of weeks in a sprint
 * @return {number} - The total number of available working days in the sprint
 */
export function calculateAvailableWorkingDays(weeksPerSprint) {
  const daysPerWeek = 5;
  return weeksPerSprint * daysPerWeek;
}

/**
   * Function to calculate total productive hours
   * @param {object} values - Form values containing availableWorkingDays, hoursPerDay, and percentageOfTime
   * @return {number} - The total productive hours
   */
export function calculateTotalProductiveHours(values) {
  const totalHours = values.availableWorkingDays * values.hoursPerDay;
  const percentage = values.percentageOfTime / 100;
  return totalHours * percentage;
}

/**
   * Function to calculate total scrum time
   * @param {object} values - Form values containing various scrum-related hours
   * @return {number} - The total scrum time
   */
export function calculateScrumTime(values) {
  return (
    values.sprintPlanning
      + values.dailyStandups
      + values.sprintReview
      + values.sprintRetrospective
      + values.productBacklogRefinement
  );
}

/**
   * Function to calculate maximum productive hours
   * @param {number} totalProductiveHours - The total productive hours
   * @param {number} scrumTime - The total scrum time
   * @return {number} - The maximum productive hours
   */
export function calculateMaximumProductiveHours(totalProductiveHours, scrumTime) {
  return totalProductiveHours - scrumTime;
}

/**
   * Function to calculate daily standups time
   * @param {number} availableWorkingDays - The number of available working days
   * @return {number} - The total time spent in daily standups
   */
export function calculateDailyStandups(availableWorkingDays) {
  const dailyStandups = ((15 * availableWorkingDays) - 15) / 60;
  return dailyStandups < 0 ? 0 : dailyStandups;
}

/**
   * Function to calculate product backlog refinement time
   * @param {number} availableWorkingDays - The number of available working days
   * @return {number} - The total time spent in product backlog refinement
   */
export function calculateProductBacklogRefinement(availableWorkingDays) {
  return (availableWorkingDays * 8) * 0.10;
}

/**
   * Function to calculate hours lost to vacation
   * @param {number} vacationDays - The number of vacation days
   * @param {number} hoursPerDay - The number of hours worked per day
   * @return {number} - The total hours lost to vacation
   */
export function calculateVacationHours(vacationDays, hoursPerDay) {
  return vacationDays * hoursPerDay;
}

/**
   * Function to calculate hours lost to non-scrum meetings
   * @param {number} nonScrumMeetings - The number of hours spent in non-scrum meetings
   * @return {number} - The total hours lost to non-scrum meetings
   */
export function calculateNonScrumMeetingHours(nonScrumMeetings) {
  return nonScrumMeetings;
}

/**
   * Function to calculate hours lost to interrupts
   * @param {number} interrupts - The number of hours lost to interrupts
   * @return {number} - The total hours lost to interrupts
   */
export function calculateInterruptHours(interrupts) {
  return interrupts;
}
