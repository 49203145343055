import React, { useState } from 'react';
import { Box } from '@mui/material';
import Videos from './Videos';
import SearchBar from './utils/SearchBar';

const VideoSearchPage = () => {
  const [query, setQuery] = useState('');

  const handleSearch = (searchValue) => {
    setQuery(searchValue);
  };

  return (
    <Box>
      <SearchBar onSearch={handleSearch} />
      <Videos query={query} />
    </Box>
  );
};

export default VideoSearchPage;
