import {
  people01,
  people02,
  people03,
  facebook,
  instagram,
  linkedin,
  twitter,
  send,
  quotes,
  shield,
} from '../assets';

export const navLinks = [
  {
    id: '',
    title: 'Home',
  },
  {
    id: 'community',
    title: 'Evergreen Community',
  },
];

export const features = [
  {
    id: 'feature-1',
    icon: quotes,
    title: 'Create Your Own GPT',
    content:
      'Learn to create your own GPT tailored to your specific needs. Whether it’s managing tasks, generating content, or assisting with research, we provide step-by-step training to empower you to build and deploy your own AI tools.',
  },
  {
    id: 'feature-2',
    icon: send,
    title: 'Automate Complex Tasks',
    content:
      'Discover how AI can automate repetitive and complex tasks. Our training focuses on leveraging AI to enhance productivity, allowing you to focus on higher-level strategic work while the AI handles the routine.',
  },
  {
    id: 'feature-3',
    icon: shield,
    title: 'Personalized AI Assistants',
    content:
      'Develop personalized AI assistants that understand your workflow and adapt to your preferences. Our courses guide you through creating AI that provides real-time assistance, helping you achieve more in less time.',
  },
];

export const faqs = [
  {
    id: 'faq-1',
    question: 'What is AI Role Mastery?',
    answer: 'AI Role Mastery is our unique approach to training, designed to enhance your ability to utilize AI in your specific role. By focusing on practical applications and personalized learning, we ensure you gain the skills to effectively integrate AI into your daily tasks.',
  },
  {
    id: 'faq-2',
    question: 'Do I need any prior experience with AI?',
    answer: 'No prior experience with AI is necessary. Our courses are structured to accommodate all skill levels, starting from the basics and advancing to more complex concepts. You will receive comprehensive support throughout your learning journey.',
  },
  {
    id: 'faq-3',
    question: 'How long does it take to create a GPT?',
    answer: 'Creating a GPT can take less time than performing a complex task manually. Our training is designed to be efficient and practical, enabling you to create functional AI tools in a matter of hours, depending on your pace and the complexity of the task.',
  },
];

export const feedback = [
  {
    id: 'feedback-1',
    content:
      'Money is only a tool. It will take you wherever you wish, but it will not replace you as the driver.',
    name: 'Herman Jensen',
    title: 'Founder & Leader',
    img: people01,
  },
  {
    id: 'feedback-2',
    content:
      "Money makes your life easier. If you're lucky to have it, you're lucky.",
    name: 'Steve Mark',
    title: 'Founder & Leader',
    img: people02,
  },
  {
    id: 'feedback-3',
    content:
      'It is usually people in the money business, finance, and international trade that are really rich.',
    name: 'Kenn Gallagher',
    title: 'Founder & Leader',
    img: people03,
  },
];

export const stats = [
  {
    id: 'stats-1',
    title: 'People Coached',
    value: '10K+',
  },
  {
    id: 'stats-2',
    title: 'Trusted by Company',
    value: '100+',
  },
  {
    id: 'stats-3',
    title: 'People Certified',
    value: '1000+',
  },
];

export const footerLinks = [
  {
    title: 'Useful Links',
    links: [
      {
        name: 'Content',
        link: 'https://www.hoobank.com/content/',
      },
      {
        name: 'How it Works',
        link: 'https://www.hoobank.com/how-it-works/',
      },
      {
        name: 'Create',
        link: 'https://www.hoobank.com/create/',
      },
      {
        name: 'Explore',
        link: 'https://www.hoobank.com/explore/',
      },
      {
        name: 'Terms & Services',
        link: 'https://www.hoobank.com/terms-and-services/',
      },
    ],
  },
  {
    title: 'Community',
    links: [
      {
        name: 'Help Center',
        link: 'https://www.hoobank.com/help-center/',
      },
      {
        name: 'Partners',
        link: 'https://www.hoobank.com/partners/',
      },
      {
        name: 'Suggestions',
        link: 'https://www.hoobank.com/suggestions/',
      },
      {
        name: 'Blog',
        link: 'https://www.hoobank.com/blog/',
      },
      {
        name: 'Newsletters',
        link: 'https://www.hoobank.com/newsletters/',
      },
    ],
  },
  {
    title: 'Partner',
    links: [
      {
        name: 'Our Partner',
        link: 'https://www.hoobank.com/our-partner/',
      },
      {
        name: 'Become a Partner',
        link: 'https://www.hoobank.com/become-a-partner/',
      },
    ],
  },
];

export const socialMedia = [
  {
    id: 'social-media-1',
    icon: instagram,
    link: 'https://www.instagram.com/',
  },
  {
    id: 'social-media-2',
    icon: facebook,
    link: 'https://www.facebook.com/',
  },
  {
    id: 'social-media-3',
    icon: twitter,
    link: 'https://www.twitter.com/',
  },
  {
    id: 'social-media-4',
    icon: linkedin,
    link: 'https://www.linkedin.com/',
  },
];

export const clients = [
  {
    id: 'client-1',
    logo: '',
  },
];
