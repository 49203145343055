// PricingPage.jsx
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Card, CardActions, CardContent, CardHeader, Typography, Switch, FormControlLabel, Button, Modal } from '@mui/material';
import ActiveCampaignForm from './ActiveCampaignForm';

const PricingCard = ({ title, price, features, handleOpen, handleStripePayment }) => (
  <Card
    variant="outlined"
    color="primary"
    sx={{
      raised: true,
      bgcolor: 'background.paper',
      width: '100%',
      overflow: 'auto',
    }}
  >
    <CardContent>
      <CardHeader title={title} titleTypographyProps={{ align: 'center' }} />
      <Typography color="textPrimary" variant="h3">
        {price}
      </Typography>
      {features.map((feature, index) => (
        <Typography variant="body1" gutterBottom key={index}>
          {feature}
        </Typography>
      ))}
      <CardActions>
        {title === 'Teams'
          ? <Button variant="outlined" color="primary" sx={{ mt: 2, width: '100%' }} onClick={handleOpen}>Contact Sales</Button>
          : <Button variant="contained" sx={{ mt: 2, width: '100%', backgroundColor: '#FF9900' }} onClick={handleStripePayment}>Start Trial Now!</Button>}
      </CardActions>
    </CardContent>
  </Card>
);

const PricingPage = () => {
  const [isYearly, setIsYearly] = useState(false);
  const navigate = useNavigate();
  const goToRoot = () => {
    navigate('/');
  };

  const toggleIsYearly = () => setIsYearly(!isYearly);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const individualPrice = isYearly ? '$390 / year' : '$39 / month';
  const individualFeatures = [
    'Unlimited words generated by AI',
    '1 seat',
    'Agile lens',
    'Memories',
    'Unlimited lens assistants',
  ];

  const businessFeatures = [
    'Unlimited words generated by AI',
    'For teams 10+ seats with SSO',
    'Agile lens',
    'Unlimited memories',
    'Company or industry lens',
    'Custom lens assistants',
  ];

  const handleStripePayment = async () => {
    try {
      const appUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${appUrl}/api/payment/paywithstripe`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ isYearly }),
      });

      if (response.status === 200) {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.includes('application/json')) {
          const { url } = await response.json();
          // launch the Stripe Checkout page
          window.location.href = url;
        } else {
          console.error('Response is not in JSON format');
        }
      } else {
        console.error('Request failed with status:', response.status);
        // Handle the error or display an error message to the user
      }
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle the error or display an error message to the user
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          paddingRight: 2, // Adjust as needed for spacing
          paddingTop: 2, // Adjust as needed for spacing
        }}
      >
        <Button variant="outlined" color="primary" sx={{ mr: 2 }} onClick={goToRoot}>
          Home
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          jusifyContent: 'center',
          backgroundColor: '#EEEEEE',
          borderRadius: 6,
          mt: 2,
          ml: 2,
          mr: 2,
          p: [2, 4],
        }}
      >
        <FormControlLabel
          sx={{ marginBottom: 6, marginTop: 6, color: 'green' }}
          control={<Switch color="secondary" checked={isYearly} onChange={toggleIsYearly} />}
          label={isYearly ? 'Yearly Billing (15% off)' : 'Monthly Billing'}
        />

        <Grid container spacing={4} justifyContent="center">
          <Grid item xs={12} sm={6}>
            <PricingCard
              title="Individual License"
              price={individualPrice}
              features={individualFeatures}
              handleStripePayment={handleStripePayment}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <PricingCard
              title="Teams"
              price="Custom Pricing"
              features={businessFeatures}
              handleOpen={handleOpen}
            />
          </Grid>
          <Modal open={open} onClose={handleClose}>
            <ActiveCampaignForm />
          </Modal>
        </Grid>
      </Box>
    </>
  );
};

export default PricingPage;
