import React, { useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

const Alert = (props, ref) => <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;

const SEVERITY = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

const flashMessage = {
  info: (message) => (
    <FlashMessage severity={SEVERITY.info} message={message} />
  ),

  error: (message) => (
    <FlashMessage severity={SEVERITY.error} message={message} />
  ),

  success: (message) => (
    <FlashMessage severity={SEVERITY.success} message={message} />
  ),

  warning: (message) => (
    <FlashMessage severity={SEVERITY.warning} message={message} />
  ),
};

const FlashMessage = ({ severity, message }) => {
  const [open, setOpen] = useState(true);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="">
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export { flashMessage };

