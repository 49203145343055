import React from 'react';
import { Paper, IconButton } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { copyTextToClipboard } from './utils/clipboardUtils';

const ChatText = ({ transmission, children }) => {
  const theme = useTheme();
  const handleCopy = async () => {
    await copyTextToClipboard(children.toString());
  };

  const paperStyle = {
    ...theme.typography.body2,
    fontSize: '0.875rem',
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    maxWidth: '100%',
    wordWrap: 'break-word',
    borderTopLeftRadius: transmission === 'ai' ? '2px' : undefined,
    borderTopRightRadius: transmission === 'human' ? '2px' : undefined,
    backgroundColor: transmission === 'ai' ? 'linear-gradient(to right, #6a3093, #a044ff)' : 'linear-gradient(to right, #9575cd, #b39ddb)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  };

  return (
    <Paper style={paperStyle} elevation={transmission ? 4 : 1}>
      <div style={{ width: '95%', marginLeft: theme.spacing(2) }}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>
          {children}
        </ReactMarkdown>
      </div>
      {transmission === 'ai' && (
        <IconButton onClick={handleCopy} aria-label="copy" size="small" style={{ alignSelf: 'flex-end', margin: '8px 0' }}>
          <ContentCopyIcon fontSize="inherit" />
        </IconButton>
      )}
    </Paper>
  );
};

export default ChatText;
