import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Roadmap = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.open('https://evergreen-ai.canny.io/', '_blank');
    navigate('/');
  }, [navigate]);

  return null;
};

export default Roadmap;
